import React, { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from '../ui/button.jsx';
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel
} from "../ui/form.jsx"
import { Textarea } from '../ui/textarea.jsx';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '../../components/ui/dialog';
import { MessageAlert } from '../ui/message_alert';
import UploadFileComment from '../ui/uploadFileComment.js';
import ImageRende from '../ImageRende.js';
import { add_comment, upload_multimedia } from '../../lib/supabaseProfile.js';
import { useNavigate } from 'react-router-dom';


const formSchema = z.object({
  description: z.string().min(
    1, { message: "El comentario es requerido" }
  ),
});

function AddComment({
  user_uuid,
  user,
  profile_uuid,
  mediallon,
  open,
  setOpen
}) {
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [content, setContent] = useState([]);
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState(null); // Almacenar el archivo seleccionado
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: ""
    },
  });

  const onSubmit = async (dataForm) => {
    setLoading(true);
    setOpen(true);
    // Subir la imagen y obtener la URL pública
    let publicURL = "";
    let fileData = {};

    if (imageFile) {
      const fileName = `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;
      let fileExt = imageFile.type.split("/")[1];

      let filePath = "";
      if (profile_uuid) {
        filePath = `${user.user_uuid}/${profile_uuid}/${fileName}.${fileExt}`;
      } else {
        filePath = `${user.user_uuid}/${fileName}.${fileExt}`;
      }

      const data = await upload_multimedia(filePath, imageFile, true);
      if (data && data.status_code === 200) {
        publicURL = data.data;
        fileData = {
          extension: null,
          size: imageFile.size / 1024 / 1024,
          url: publicURL,
          type: null,
          order: 1,
          is_approved: false,
          is_comment: true,
        };
      } else {
        setMessage("Error al subir la imagen");
        setLoading(false);
        setOpenMessage(true);
        return;
      }
    }

    if (!imageFile) {
      fileData.size = 0;
      fileData.order = 0;
      fileData.is_approved = false;
      fileData.is_comment = true;
    }



    // Combinar los datos del formulario y los datos de la imagen
    const values = {
      user_uuid: user.user_uuid,
      profile_uuid: profile_uuid,
      ...dataForm,
      ...fileData,
    };

    // Crear el comentario
    const response = await add_comment(values, user_uuid);

    if (response && response.success && response.status_code === 200) {
      setMessage("El comentario ha sido registrado con éxito y está actualmente en espera de aprobación.");
      // Actualiza el estado local con el nuevo comentario
      setContent([...content, response.data]);
    } else {
      setMessage("Error al crear el comentario");
    }

    setLoading(false);
    setOpen(false);
    setOpenMessage(true);

  };

  const handleButtonClick = () => {
    //navigate('/dashboard');
    setOpen(false);
  }

  const handleAddComment = () => {
    if (user){
      setOpen(true);
    }
    else {
      localStorage.setItem("action", "add_comment")
      localStorage.setItem("redirect", "/perfil/" + mediallon)
      navigate("/auth") 
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen} >
        <DialogTrigger asChild>
          <Button className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded mx-1 md:mx-0" onClick={handleAddComment}>
            + Agregar Comentario
          </Button>
        </DialogTrigger>
        <DialogContent className="flex flex-col w-11/12 sm:w-full max-w-md items-center justify-center px-6 rounded-lg">
          <div className="flex items-center justify-center mb-2">
            <ImageRende src={user?.avatar_url} alt="avatar" className="mr-3 w-10 h-10  rounded-full" />
            <div>
              <p className="text-lg font-semibold text-black">{user?.name}</p>
            </div>
          </div>
          <hr className="my-1 border-gray-300 w-full" />
          <p className="mb-1 text-center text-sm text-wrap">
            Cada comentario e imagen deberá ser aprobado por el propietario del perfil
          </p>
          <div className="bg-white p-6 rounded-lg max-w-md">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <div className="grid grid-row-1 gap-4">
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Comentario: *</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Comentario *"
                            {...field}
                            className="w-full px-4 py-3 mb-0 h-28 font-medium bg-white text-black text-base focus:outline-none focus:bg-white border border-gray-300 resize-none"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <UploadFileComment
                    user_uuid={user?.user_uuid}
                    profile_uuid={profile_uuid}
                    loading={loading}
                    setLoading={setLoading}
                    setImageFile={setImageFile}
                    type="content"
                  />
                  <Button
                    type="submit"
                    className="bg-red_1 hover:bg-red-700 m-0 text-white font-bold py-2 px-4 rounded"
                  >
                    Subir
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
      {message.length > 0 && <MessageAlert
        open={openMessage}
        setOpen={setOpenMessage}
        message={message}
        messageContinue="Aceptar"
        actionContinue={handleButtonClick}
      />}
    </>
  );
}

export default AddComment;
