import { supabase } from "../lib/supabaseClient";
import { saveError } from "./saveError";
import CryptoJS from 'crypto-js';

export const response = {
  success: false,
  data: null,
  status_code: null,
};

// const { data, error } = await supabase
//   .rpc('get_all_relationship_states_countries');
export async function getProfile(profile_uuid, user_uuid) {
  const { data, error } = await supabase.rpc("get_profile", {
    p_data: { profile_uuid: profile_uuid },
  });
  if (error) {
    await saveError({
      process: "get_profile",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function getMedallions(user_uuid) {
  const { data, error } = await supabase
    .from("medallion")
    .select(
      `
      medallion_uuid,
      user_uuid,
      profile_uuid
    `
    )
    .eq("user_uuid", user_uuid)

  if (error) {
    await saveError({
      process: "get_medallions",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function setMedallion(profile_uuid, medallions, user_uuid) {
  const { data, error } = await supabase
    .from("medallion")
    .update({ profile_uuid: profile_uuid })
    .in("medallion_uuid", medallions);

  if (error) {
    await saveError({
      process: "set_medallion",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function getProfileData(value_uuid, user_uuid) {
  // try {
  let { data, error, status } = await supabase
    .from('medallion')
    .select('profile_uuid')
    .eq('medallion_uuid', value_uuid)
    .single();


  if (status !== 200) {

    ({ data, error, status } = await supabase
    .rpc('get_profile', { p_data: { profile_uuid: value_uuid } }))
    
    console.log("data",data,"error", error);
    if (status !== 200) {
      await saveError({
        process: "get_profile_data",
        error: error,
        user_uuid: user_uuid,
        fixed: false,
      });
      response.success = false;
      response.data = error;
      response.status_code = 404;
    }
    else {
      response.data = data;
      response.status_code = 201;
      response.success = true
    }

    return response;
  }
  // response.member_id: <value>

  if (!data.profile_uuid) {
    await saveError({
      process: "get_profile_data",
      error: "medallion without linked user",
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = 'medallion without linked user';
    response.status_code = 204;
    return response;
  }

  ({ data, error, status } = await supabase
    .from('profile')
    .select(`
      profile_uuid,
      first_name,
      last_name,
      date_birth,
      date_departure,
      biography,
      avatar_url,
      banner_url,
      phrase,
      date_created,
      date_updated,
      relationship (
        description,
        state
      ),
      state (
        name,
        country (
          sort_name,
          name_en,
          name_es,
          phone_code
        )
      ),
      is_private,
      password
    `)
    .eq('profile_uuid', data.profile_uuid)

    .single()
  );
  data.status = true;
  response.data = data;
  response.status_code = 200;
  response.success = true;

  return response;
}

export async function get_relationship_states_countries(user_uuid) {
  const { data, error } = await supabase.rpc(
    "get_all_relationship_states_countries"
  );

  if (error) {
    await saveError({
      process: "get_relationship_states_countries",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  if (!data.countries) {
    data.countries = [];
  }

  if (!data.relationships) {
    data.relationships = [];
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function get_states_by_countries(country_id, user_uuid) {
  const { data, error } = await supabase
    .from("state")
    .select(
      `
      name,
      state_id
    `
    )
    .eq("country_id", country_id);

  if (error) {
    await saveError({
      process: "get_states_by_countries",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function post_profile(profile, user_uuid) {
  profile.user_uuid = user_uuid;
  profile.first_name = `${profile.first_name} ${profile.second_name}`.trim();
  const { second_name, ...profileWithoutSecondName } = profile;
  const { country, ...profileWithoutCountry } = profileWithoutSecondName;
  profileWithoutCountry.password = hashear_password(profileWithoutCountry);
  const { data, error } = await supabase.rpc("insert_new_profile", {
    p_profile: profileWithoutCountry,
  });

  if (error) {
    await saveError({
      process: "post_profile",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function get_profiles(user_uuid, isEdit) {
  const p_data = { user_uuid, isEdit };
  const { data, error } = await supabase.rpc("get_profiles", { p_data });

  if (error) {
    await saveError({
      process: "get_profiles",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function upload_multimedia(path, file, search) {
  let data, error;

  if (search) {
    // console.log(path.split('/'));

    const directory = path.substring(0, path.lastIndexOf("/"));

    const fileNameWithExt = path.substring(path.lastIndexOf("/") + 1);
    const fileNameWithoutExt = fileNameWithExt.replace(/\.[^/.]+$/, "");
    ({ data, error } = await supabase.storage
      .from("multimedia")
      .list(directory, {
        search: fileNameWithoutExt,
      }));

    response.success = false;
    response.status_code = 500;

    if (error) {
      response.data = error;
      return response;
    }

    if (data.length > 0) {
      const fileName = data[0].name;
      ({ data, error } = await supabase.storage
        .from("multimedia")
        .remove([`${directory}/${fileName}`]));

      if (error) {
        response.data = error;
        return response;
      }
    }
  }

  ({ data, error } = await supabase.storage
    .from("multimedia")
    .upload(path, file));

  if (error) {
    response.data = error;
    return response;
  }

  if (data) {
    response.success = true;
    response.data = data.path;
    response.status_code = 200;
  }
  return response;
}

export async function remove_permissions(profile_uuid, user_uuid) {
  const { data, error } = await supabase
    .from("user_profile")
    .delete()
    .eq("profile_uuid", profile_uuid)
    .eq("user_uuid", user_uuid);

  if (error) {
    await saveError({
      process: "remove_permissions",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function remove_content(content_uuid, user_uuid, url) {
  let { data, error } = await supabase
    .from("content")
    .delete()
    .eq("content_uuid", content_uuid);

  if (error) {
    await saveError({
      process: "remove_content",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  ({ data, error } = await supabase.storage.from("multimedia").remove([url]));

  if (error) {
    await saveError({
      process: "remove_content",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function remove_profile(profile_uuid, user_uuid) {
  const path = `${user_uuid}/${profile_uuid}`;

  // console.log("asdasdas");
  let { data, error } = await supabase.storage.from("multimedia").list(path);

  // console.log("---->", data, error)
  if (error) {
    await saveError({
      process: "remove_profile_get_multimedia",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  if (data.length > 0) {
    const filePaths = data.map((file) => `${path}/${file.name}`);

    ({ data, error } = await supabase.storage
      .from("multimedia")
      .remove(filePaths));

    if (error) {
      await saveError({
        process: "remove_profile_remove_multimedia",
        error: error,
        user_uuid: user_uuid,
        fixed: false,
      });
      response.success = false;
      response.data = error;
      response.status_code = 500;
      return;
    }
  }

  ({ data, error } = await supabase
    .from("profile")
    .delete()
    .eq("profile_uuid", profile_uuid));

  if (error) {
    await saveError({
      process: "remove_profile",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function update_user_images(url, user_uuid) {
  const { data, error } = await supabase
    .from("user")
    .update({ avatar_url: url })
    .eq("user_uuid", user_uuid);

  if (error) {
    await saveError({
      process: "update_user_images",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function update_profile_avatar(url, profile_uuid, user_uuid) {
  console.log(url);
  const { data, error } = await supabase
    .from("profile")
    .update({ avatar_url: url })
    .eq("profile_uuid", profile_uuid);

  // console.log(profile_uuid)

  if (error) {
    await saveError({
      process: "update_profile_avatar",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  // console.log(data, error);

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function update_profile_banner(url, profile_uuid, user_uuid) {
  const { data, error } = await supabase
    .from("profile")
    .update({ banner_url: url })
    .eq("profile_uuid", profile_uuid);

  if (error) {
    await saveError({
      process: "update_profile_banner",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  // console.log(data, error);

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function update_profile(profile, profile_uuid, user_uuid) {
  if (profile.second_name === undefined) {
    profile.second_name = "";
  }
  console.log(profile.second_name)
  if(!profile.is_private){
    profile.password=null;
  }
  profile.first_name = `${profile.first_name} ${profile.second_name}`.trim();
  const { second_name, ...profileWithoutSecondName } = profile;
  const { country_id, re_pass, ...profileWithoutCountry } = profileWithoutSecondName;
  console.log(profile);
  const { data, error } = await supabase
    .from("profile")
    .update(profileWithoutCountry)
    .eq("profile_uuid", profile_uuid);

  if (error) {
    await saveError({
      process: "update_profile",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function upload_profile_content(values, user_uuid) {
  const { data, error } = await supabase.rpc("insert_and_return_content", {
    p_data: values,
  });

  console.log(data, error);

  if (error) {
    await saveError({
      process: "upload_profile_content",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function get_profile_content(profile_uuid, user_uuid) {
  const p_data = { profile_uuid };
  let { data, error } = await supabase.rpc("get_content_by_profile", {
    p_data,
  });

  if (data) {
    if (data.contents)
      data.contents = data.contents.sort((a, b) => a.order - b.order);
  }

  // console.log(data)

  if (error) {
    await saveError({
      process: "get_profile_content",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function get_comments_by_profile(profile_uuid, user_uuid) {
  const p_data = { profile_uuid };
  let { data, error } = await supabase.rpc("get_comments_by_profile", {
    p_data,
  });
  if (data) {
    data = data.sort(
      (a, b) => new Date(b.creation_datetime) - new Date(a.creation_datetime)
    );
  }

  if (error) {
    await saveError({
      process: "get_comments_by_profile",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

async function delete_image(url) {
  const { data, error } = await supabase.storage
    .from("multimedia")
    .remove([url]);

  return { data, error };
}

export async function delete_comment(content_uuid, url, user_uuid) {
  const res = await delete_image(url);
  if (res.error) {
    await saveError({
      process: "delete_image_comment",
      error: res.error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = res.error;
    response.status_code = 500;
    return response;
  }
  const { data, error } = await supabase
    .from("content")
    .delete()
    .eq("content_uuid", content_uuid);

  if (error) {
    await saveError({
      process: "delete_comment",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function update_approved_comment(content_uuid, user_uuid) {
  const { data, error } = await supabase
    .from("content")
    .update({ is_approved: true })
    .eq("content_uuid", content_uuid);

  if (error) {
    await saveError({
      process: "update_approved_comment",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function delete_share_permissions(profile_uuid, user_uuid) {
  const { data, error } = await supabase
    .from("user_profile")
    .delete()
    .eq("profile_uuid", profile_uuid)
    .eq("user_uuid", user_uuid);

  if (error) {
    await saveError({
      process: "delete_share_permissions",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }
  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function update_share_permissions(profile_uuid, user_uuid, state) {
  const { data, error } = await supabase
    .from("user_profile")
    .update({ editor: state })
    .eq("profile_uuid", profile_uuid)
    .eq("user_uuid", user_uuid);

  if (error) {
    await saveError({
      process: "update_share_permissions",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }
  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function insert_share_profile(
  profile_uuid,
  email,
  user_uuid_prop
) {
  let { data, error } = await supabase
    .from("user")
    .select("user_uuid")
    .eq("email", email);

  if (error) {
    await saveError({
      process: "insert_share_profile_get_user",
      error: error,
      user_uuid: user_uuid_prop,
      fixed: false,
    });
    return {
      success: false,
      data: error,
      status_code: 500,
    };
  }
  if (data.length === 0) {
    await saveError({
      process: "insert_share_profile_get_user",
      error: "User not found",
      user_uuid: user_uuid_prop,
      fixed: false,
    });
    return {
      success: false,
      data: "User not found",
      status_code: 404,
    };
  }

  const user_uuid = data[0].user_uuid;

  ({ data, error } = await supabase.from("user_profile").insert({
    profile_uuid,
    user_uuid,
    owner: false,
    state: true,
    editor: false,
  }));

  if (error) {
    if (error.code === "23505") {
      await saveError({
        process: "insert_share_profile",
        error: "User already has access to this profile",
        user_uuid: user_uuid_prop,
        fixed: false,
      });
      return {
        success: false,
        data: "User already has access to this profile",
        status_code: 409,
      };
    }
    await saveError({
      process: "insert_share_profile",
      error: error,
      user_uuid: user_uuid_prop,
      fixed: false,
    });
    return {
      success: false,
      data: error,
      status_code: 500,
    };
  }

  const user = {
    user_uuid: null,
    name: null,
    avatar_url: null,
    edit: false,
    state: false,
  };

  ({ data, error } = await supabase
    .from("user")
    .select(
      `
      user_uuid,
      name,
      avatar_url
      `
    )
    .eq("user_uuid", user_uuid)
    .single());

  if (error) {
    await saveError({
      process: "insert_share_profile_get_user_data",
      error: error,
      user_uuid: user_uuid_prop,
      fixed: false,
    });
    return {
      success: false,
      data: error,
      status_code: 500,
    };
  }

  user.user_uuid = data.user_uuid;
  user.name = data.name;
  user.avatar_url = data.avatar_url;

  ({ data, error } = await supabase
    .from("user_profile")
    .select(
      `
      editor,
      state
    `
    )
    .eq("profile_uuid", profile_uuid)
    .eq("user_uuid", user_uuid)
    .single());

  if (error) {
    await saveError({
      process: "insert_share_profile_get_user_profile_data",
      error: error,
      user_uuid: user_uuid_prop,
      fixed: false,
    });
    return {
      success: false,
      data: error,
      status_code: 500,
    };
  }

  user.edit = data.editor;
  user.state = data.state;

  return {
    success: true,
    data: user,
    status_code: 200,
  };
}

export async function update_report_comment(
  content_uuid,
  description,
  user_uuid
) {
  const { data, error } = await supabase
    .from("content")
    .update({ is_reported: true, report_message: description })
    .eq("content_uuid", content_uuid);

  if (error) {
    await saveError({
      process: "update_report_comment",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function add_comment(values, user_uuid) {
  values.is_approved = false;
  values.is_comment = true;
  values.extension = null;
  values.type = null;

  const { data, error } = await supabase.from("content").insert(values);

  console.log(data, error);

  if (error) {
    await saveError({
      process: "add_comment",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

export async function update_description_content(
  content_uuid,
  description,
  user_uuid
) {
  const { data, error } = await supabase
    .from("content")
    .update({ description: description })
    .eq("content_uuid", content_uuid);

  if (error) {
    await saveError({
      process: "update_description_content",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}


export async function get_comments_by_owner(user_uuid) {

  const {data, error} = await supabase.rpc("get_comments_by_owner", {
    p_data: { user_uuid},
  });

  if (error) {
    await saveError({
      process: "get_comments_by_owner",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  return response;
}

const secretKey = 'secrectKey_latido_eterno_@@@@@@@@@';
export function verify_password(data,passs){
  const {password} = data;
  const {pass} = passs;
  const isMatch = CryptoJS.AES.decrypt(pass, secretKey).toString(CryptoJS.enc.Utf8) === password;
  return isMatch;
}


export function hashear_password(data){
  const bytes = CryptoJS.AES.encrypt(data.password, secretKey);
  console.log("hashear_password",bytes.toString());
  return bytes.toString();
}

export function deshashear_password(data){
  const bytes = CryptoJS.AES.decrypt(data.password, secretKey);
  console.log("deshashear_password",bytes.toString(CryptoJS.enc.Utf8));
  return bytes.toString(CryptoJS.enc.Utf8);
}
