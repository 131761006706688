/* eslint-disable no-unused-vars */

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export function ImageRende({
  src,
  alt,
  className = "",
  isBanner = false,
  isComentario = false,
}) {
  const placeholder = isBanner ? 'bannerfinal.png' : 'profile.jpeg';

  const imageUrl = src && src.startsWith('https')
    ? src
    : `https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${src || placeholder}?${new Date().getTime()}`;


  if (isComentario && !src) {
    return;
  }
  return (
    <LazyLoadImage
      src={imageUrl}
      alt={alt}
      className={className}
      placeholderSrc={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${placeholder}`}
      effect="blur"
      wrapperClassName={isBanner ? 'w-full' : ''}
    />
  );
}

export default ImageRende;
