import React, { useEffect, useState } from "react";
import { getProfileData } from "../lib/supabaseProfile";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ListMediaContents from "../components/Profile/ListMediaContents";
import NotFound from "../components/NotFound";
import { Loading } from "../components/ui/loading";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../components/ui/tabs";

import { get_profile_content } from "../lib/supabaseProfile";

import { useAuth } from "../contexts/AuthContext";
import ImageRende from "../components/ImageRende";
import ListComments from "../components/Profile/ListComments";
import { ScrollArea, ScrollBar } from "../components/ui/scroll-area";
import { ActionButton } from "../components/ui/ActionButton";
import MessageAlert from "../components/ui/message_alert";
import { Dialog, DialogContent, DialogTrigger } from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import CardPrivate from "../components/Profile/CardPrivate";
const searchUserByUuid = async (user_uuid, users) => {
  if (!user_uuid) return false;
  if (!users) return false;
  const user = users.find((user) => user.user_uuid === user_uuid);
  if (user) {
    return true;
  }
  return false;
};

function Profile() {
  const { value } = useParams();
  let query = new URLSearchParams(useLocation().search);
  let location = useLocation();
  let action = query.get("action");
  const { profile, getSession } = useAuth();
  const [lookTab, setLookTab] = useState("contenido");
  const [open, setOpen] = useState(false);
  const [memberData, setMemberData] = useState({
    status: "",
    banner_url: "",
    avatar_url: "",
    first_name: "",
    last_name: "",
    phrase: "",
    profile_uuid: "",
    state: {
      name: "",
      country: {
        name_en: "",
      },
    },
    medallions: [
      {
        state: false,
        user_uuid: "",
        medallion_uuid: "",
        medallion_group_id: 0,
      },
    ],
    is_private: false,
    password: "",
  });
  const [countryFlagUrl, setCountryFlagUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [mediaContents, setMediaContents] = useState([]);
  const [owner_uuid, setOwnerUuid] = useState(null);
  const [access, setAccess] = useState(false);
  const [owner, setOwner] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      const session = await getSession();

      if (!session) {
        return;
      }

      if (profile) {
        if (profile.user_uuid === owner_uuid) {
          setOwner(true);
        }
      }
    };
    fetchProfile();
  }, [profile, owner_uuid, getSession, navigate]);

  useEffect(() => {
    if (action === "comment") {
      setLookTab("comentarios");
      const newUrl = `${location.pathname}`;
      window.history.replaceState(null, "", newUrl);
      setOpen(true);
    }
  }, [action, location]);

  useEffect(() => {
    const getProfileContent = async () => {
      if (memberData.profile_uuid && memberData.profile_uuid.length > 0) {
        const response = await get_profile_content(memberData.profile_uuid);
        if (response.success) {
          console.log(response.data);
          if (response.data.contents) setMediaContents(response.data.contents);
          else {
            setMediaContents([]);
          }
        }
      }
    };
    getProfileContent();
  }, [memberData.profile_uuid]);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const data = await getProfileData(value, profile?.user_uuid);

      // existe error 404 (medallon no encontra)
      // existe error 204 (medallon sin usuario)
      if (data.status_code === 201) {
        if (data.status_code === 201) {
          if (profile === null) {
            setOwner(data.data.owner);
            setLoading(false);
            setNotFound(true);
            return;
          }
        }
        setNotFound(false);
        console.log("Data", data);
        let profile_data = {
          banner_url: "",
          avatar_url: "",
          first_name: "",
          last_name: "",
          phrase: "",
          profile_uuid: "",
          state: {
            name: "",
            country: {
              name_en: "",
            },
          },
          medallions: [
            {
              state: false,
              user_uuid: "",
              medallion_uuid: "",
              medallion_group_id: 0,
            },
          ],
          is_private: false,
          password: "",
        };
        if (data.data.profile) {
          profile_data = data.data.profile;
          profile_data.medallions = data.data.medallions;
        }

        if (profile_data.is_private) {
          console.log("is_private", profile_data.is_private);
          setOpenPass(true);
        } else {
          console.log("is Public", profile_data.is_private);
          setOpenPass(false);
        }

        const users = data.data.users;
        const edit = await searchUserByUuid(profile?.user_uuid, users);
        setOwnerUuid(data.data.owner);
        setAccess(edit);
        console.log(profile_data);
        setMemberData(profile_data);
        console.log("Profile Data", profile_data);
        if (profile_data.state && profile_data.state.country) {
          var countryName = profile_data.state.country.name_en;
          console.log("\n\n", countryName, "\n\n");
          if (countryName.includes(" ")) {
            countryName = countryName.replace(" ", "-");
          }
          const flagUrl = `https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/flags/flag-of-${countryName}.jpg`;
          setCountryFlagUrl(flagUrl);
        }
      } else if (data.status_code === 200) {
        setNotFound(false);
        setMemberData(data.data);
        if (data.data.state && data.data.state.country) {
          const countryName = data.data.state.country.name_en;
          const flagUrl = `https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/flags/flag-of-${countryName}.jpg`;
          setCountryFlagUrl(flagUrl);
        }

        setAccess(true);
      } else {
        if (data.status_code === 404) {
          setNotFound(true);
        }
        if (data.status_code === 204) {
          const logged = await getSession();
          localStorage.setItem("action", "add_medallion");
          localStorage.setItem("medallion", value);
          if (logged) {
            navigate("/dashboard");
          } else {
            navigate("/auth");
          }
        }
        setMemberData({
          status: "",
          banner_url: "",
          avatar_url: "",
          first_name: "",
          last_name: "",
          phrase: "",
          state: {
            name: "",
            country: {
              name_en: "",
            },
          },
          medallions: [
            {
              state: false,
              user_uuid: "",
              medallion_uuid: "",
              medallion_group_id: 0,
            },
          ],
          is_private: false,
          password: "",
        });
      }
      setLoading(false);
    };
    getData();
  }, [profile, value, getSession, navigate]);

  if (!owner) {
    if (notFound && !loading) {
      return <NotFound />;
    }
    if (!access && !loading) {
      return <NotFound />;
    }
  }

  const handleButtonClick = () => {
    setOpenMessage(false);
  };

  const getMedallion = () => {
    if (!memberData.medallions) {
      return "";
    }
    const len = memberData.medallions.length;
    return len > 0 ? memberData.medallions[len - 1].medallion_uuid : "";
  };

  const getLink = () => {
    var link = window.location.href;
    const medallion = getMedallion();
    if (medallion.length <= 0) {
      return "not exist";
    }
    link = `${link.split("/").slice(0, -1).join("/")}/${medallion}`;
    return link;
  };

  const compartir = async () => {
    try {
      const link = getLink();
      if (link === "not exist") {
        throw new Error("Error al copiar el enlace");
      }
      await navigator.clipboard.writeText(link);

      setMessage("Enlace copiado al portapapeles");
      setOpenMessage(true);
    } catch (error) {
      console.error(error);
      setMessage("Error al copiar el enlace");
      setOpenMessage(true);
    }
  };

  const handleShare = () => {
    setOpenShare(false);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const year = date.getFullYear();
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    return `${day} ${month}. ${year}`;
  };

  if (loading) {
    return <Loading />;
  }
  if (!memberData) {
    return <NotFound />;
  }

  return (
    <div className="min-h-screen flex flex-col mt-2 bg-white md:bg-gray-100">
      <div className="w-full flex flex-col items-center px-0 mt-14 md:mt-28">
        <div className="w-full max-w-6xl bg-white md:shadow-lg relative text-center md:text-left md:rounded-lg">
          <ImageRende
            src={memberData.banner_url}
            alt="Banner"
            className="w-full h-60 md:rounded-t-lg object-cover"
            isBanner={true}
          />
          <div className="absolute left-1/2 z-10 transform -translate-x-1/2 -translate-y-1/2 md:left-10 md:transform-none md:bottom-0">
            <ImageRende
              src={memberData.avatar_url}
              alt="Profile"
              className="rounded-full border-4 border-white w-36 h-36 object-cover"
            />
          </div>
          <div className="mt-20 md:mt-0 text-center md:text-left md:ml-60 md:pl-10 pb-3">
            <h2 className="text-3xl font-bold text-black">{`${memberData.first_name} ${memberData.last_name}`}</h2>
            <p className="text-base text-gray-400 mx-16 my-3 italic md:mx-0">
              {memberData.phrase}
            </p>
            <div className="flex justify-center md:justify-start items-center mt-4">
              <img
                src={countryFlagUrl}
                alt="Country Flag"
                className="w-6 h-6 mr-2 rounded-md"
              />
              <p className="text-base">{`${memberData.state.name}, ${memberData.state.country.name_en}`}</p>
            </div>
            {memberData.status && (
              <div className="flex justify-center md:justify-start items-center mt-4">
                <Dialog open={openShare} onOpenChange={setOpenShare}>
                  <DialogTrigger asChild>
                    <ActionButton
                      onClick={handleShare}
                      label="Compartir"
                      iconClass={"fas fa-share-alt pr-2"}
                      className="bg-transparent hover:bg-red-600 hover:text-white text-red-500 border border-red-500 shadow-none font-bold py-1 px-3 md:py-2 md:px-4 rounded-full mx-1 md:mx-0"
                    />
                  </DialogTrigger>
                  <DialogContent className="flex flex-col w-11/12 sm:w-full max-w-lg items-start justify-center px-6 rounded-lg m-0">
                    <Label className="text-xl">Compartir</Label>
                    <div className="flex w-full border border-red-500 flex-row items-center justify-between rounded-e-md">
                      <div className="px-2 py-1 w-2/4 xs:w-3/4  bg-slate-50">
                        <p className="overflow-hidden text-nowrap">
                          {getLink() === "not exist"
                            ? window.location.href
                            : getLink()}
                        </p>
                      </div>
                      <ActionButton
                        onClick={compartir}
                        label="Copiar"
                        iconClass={"far fa-clipboard pr-2"}
                        className="bg-red-500 w-2/4 xs:w-1/4 hover:bg-red-600 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded rounded-tl-none rounded-bl-none rounded-tr-sm mx-0"
                      />
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex flex-col items-center px-0 mt-6 md:mb-10">
          <div className="w-full max-w-6xl bg-white md:shadow-lg relative text-center md:text-left md:rounded-lg min-h-28">
            <Tabs defaultValue={lookTab} className="w-full">
              <TabsList className="flex items-center justify-center border-b border-gray-300 bg-white rounded-none">
                <TabsTrigger
                  value="historia"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Mi Historia
                </TabsTrigger>
                <TabsTrigger
                  value="contenido"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Contenido
                </TabsTrigger>
                <TabsTrigger
                  value="comentarios"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Comentarios
                </TabsTrigger>
                <ScrollArea className="w-full whitespace-nowrap md:hidden">
                  <TabsTrigger
                    value="historia"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Mi Historia
                  </TabsTrigger>
                  <TabsTrigger
                    value="contenido"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Contenido
                  </TabsTrigger>
                  <TabsTrigger
                    value="comentarios"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Comentarios
                  </TabsTrigger>
                  <div className="hidden">
                    <ScrollBar orientation="horizontal" />
                  </div>
                </ScrollArea>
              </TabsList>

              <TabsContent value="historia">
                <p className="text-center my-5 font-bold">
                  {`${formatDate(memberData.date_birth)} - ${formatDate(
                    memberData.date_departure
                  )}`}
                </p>
                <p className="mt-3 px-12 pt-0 text-justify whitespace-pre-wrap pb-20 md:pb-10">
                  {memberData.biography}
                </p>
              </TabsContent>

              <TabsContent value="contenido">
                <ListMediaContents
                  mediaContents={mediaContents}
                  profile_uuid={memberData.profile_uuid}
                />
              </TabsContent>

              <TabsContent
                value="comentarios"
                className="flex flex-col items-center"
              >
                {/* Comentarios aquí */}
                <ListComments
                  profile={profile}
                  profile_uuid={memberData.profile_uuid}
                  is_edit={false}
                  value={value}
                  open={open}
                  setOpen={setOpen}
                />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
      {message.length > 0 && (
        <MessageAlert
          open={openMessage}
          setOpen={setOpenMessage}
          message={message}
          messageContinue="Aceptar"
          actionContinue={handleButtonClick}
        />
      )}
      {openPass && (
        <CardPrivate pass={memberData.password}/>
      )}
    </div>
  );
}

export default Profile;
