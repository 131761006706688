
import React, { useState, useEffect } from 'react';
import SmallAvatar from "../ui/SmallAvatar";
import { Cross2Icon } from '@radix-ui/react-icons';

import {
  update_share_permissions,
  delete_share_permissions
} from "../../lib/supabaseProfile";

const ListUsers = ({
  initialUsers = [],
  profile_uuid = null,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(initialUsers);
  }, [initialUsers]);

  console.log(initialUsers)

  const handleRoleChange = async (index, value) => {
    const user = users[index];
    const newState = value === "editor";
    
    const response = await update_share_permissions(profile_uuid, user.user_uuid, newState);
    
    console.log(response)
    if (response.success) {
      const updatedUsers = users.map((user, idx) =>
        idx === index ? { ...user, edit: newState } : user
      );
      setUsers(updatedUsers);
    } 
  };

  const handleCloseClick = async (index) => {
    const user = users[index];

    const response = await delete_share_permissions(profile_uuid, user.user_uuid);
    
    if (response.success) {
      const updatedUsers = users.filter((_, idx) => idx !== index);
      setUsers(updatedUsers);
    }
  };

  return (
    <div className="space-y-4">
      {users.map((user, index) => (
        <div key={index} className="relative flex items-center justify-between p-4 border rounded-lg shadow">
          <div className="flex items-center space-x-4">
            <SmallAvatar src={user.avatar_url} />
            <div className="flex flex-col">
              <div className="font-semibold">{user.name}</div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={user.edit ? "editor" : "visualizador"}
              onChange={(e) => handleRoleChange(index, e.target.value)}
              className="p-2 border rounded-md"
            >
              <option value="editor">Editor</option>
              <option value="visualizador">Visualizador</option>
            </select>
            <button 
              onClick={() => handleCloseClick(index)}
              className="flex items-center justify-center w-6 h-6 border rounded-full text-gray-500 hover:text-gray-700"
            >
              <Cross2Icon className="h-4 w-4" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListUsers;

