import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './button';

export const ActionButton = React.forwardRef(
  ({
    iconClass,
    label,
    onClick,
    className = "",
    isMobile = false,
  }, ref) => {
    return (
      <Button ref={ref} onClick={onClick} className={`flex items-center justify-center text-lg ${className}`}>
        <div className={`flex ${isMobile ? 'flex-col items-center' : 'flex-row items-baseline'}  justify-center`}>
          {iconClass && <i className={`${iconClass} leading-none align-bottom`}></i>}
          <span className="leading-none align-bottom">{label}</span>
        </div>
      </Button>
    );
  }
)

ActionButton.propTypes = {
  iconClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
