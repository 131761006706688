import React, { useEffect, useState } from 'react';

import { Button } from '../../components/ui/button';

import ListProfiles from './ListProfiles';

import { get_profiles } from '../../lib/supabaseProfile';

import { useNavigate } from 'react-router-dom';

export function Profiles({
  profile,
}) {

  const [profiles, setProfiles] = useState([]); 
  const [user_uuid, setUserUuid] = useState('');

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/dashboard/create');
  }

  useEffect(() => {
    const getProfiles = async () => {
      if (!profile.user_uuid) return;
      const data = await get_profiles(profile.user_uuid, true);
      setUserUuid(profile.user_uuid);
      if (data.status_code === 200 && data.data) {
        setProfiles(data.data);
        console.log(data.data);
      }
    }
    getProfiles();
  }, [profile.user_uuid]);

  return (
    <div className="w-full flex flex-col ">
      <div className="w-full max-w-5xl bg-white relative p-2">
        <div className="flex justify-center mb-4 bg-white text-5xl"> 
          <Button
            onClick={handleButtonClick}
            className="px-4 py-2 bg-red_1 text-white text-base rounded data-[state=active]:hidden transition duration-300 ease-in-out transform hover:scale-110"
          >
            + Crear un nuevo perfil
          </Button>
        </div>
        <ListProfiles
          initialProfiles={profiles}
          user_uuid={user_uuid}
        />
      </div>
    </div>
  );
}

export default Profiles;

