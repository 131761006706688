import React, { useState } from "react";
// import { Button } from "../../components/ui/button";
import { Loading } from "../../components/ui/loading";

const maxFileSizeMB = 10;

export default function UploadFileComment({
  user_uuid,
  profile_uuid = null,
  loading,
  setLoading,
  setImageFile,
}) {
  const [messageError, setMessageError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleSingleFile(file);
    }
  };

  const handleSingleFile = (file) => {
    if (file && file.size / 1024 / 1024 <= maxFileSizeMB) {
      setMessageError("");
      setImageFile(file);
      previewImage(file);
    } else {
      setMessageError(`El archivo debe ser menor a ${maxFileSizeMB}MB`);
    }
  };

  const previewImage = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className="w-full h-36 border-dashed border-2 border-gray-300 rounded flex items-center justify-center cursor-pointer relative overflow-hidden">
        {selectedImage ? (
          <img src={selectedImage} className="w-full h-full object-cover" alt="Selected" />
        ) : (
          <label className="absolute inset-0 flex items-center justify-center cursor-pointer">
            <input
              type="file"
              accept="image/*"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handleFileChange}
            />
            <span className="text-center text-gray-500">
              Arrastra el archivo aquí o haz clic para seleccionar
            </span>
          </label>
        )}
      </div>
      {messageError.length>0 && (
        <div className="flex items-center text-sm justify-between mt-1.5 mb-0 text-red_1">
        {messageError}
      </div>
      )}
      
      <Loading loading={loading} />
    </>
  );
}
