import React, { useEffect, useState } from 'react';
import CardProfile from './CardProfile';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../components/ui/pagination';

export function ListProfiles({
  initialProfiles = [],
  type = 'edit',
  user_uuid = null
}) {
  const [profiles, setProfiles] = useState(initialProfiles);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProfiles, setCurrentProfiles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const profilesPerPage = 3;

  useEffect(() => {
    const indexOfLastProfile = currentPage * profilesPerPage;
    const indexOfFirstProfile = indexOfLastProfile - profilesPerPage;

    const newCurrentProfiles = profiles.slice(indexOfFirstProfile, indexOfLastProfile);
    setCurrentProfiles(newCurrentProfiles);

    const newTotalPages = Math.ceil(profiles.length / profilesPerPage);
    setTotalPages(newTotalPages);
  }, [profiles, currentPage]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setProfiles(initialProfiles);
  }, [initialProfiles]);

  const handleProfileRemove = (profile_uuid) => {
    setProfiles(profiles.filter(profile => profile.profile_uuid !== profile_uuid));
  };

  return (
    <>
      <div className="flex flex-wrap justify-center">
        {currentProfiles.map((profile, index) => (
          <div
            key={index}
            className="p-4 w-full sm:w-1/2 lg:w-1/3 flex justify-center"
          >
            <CardProfile
              name={profile.name}
              relation={profile.relation}
              date={profile.date}
              type={type}
              status={profile.status}
              imageSrc={profile.imageSrc}
              profile_uuid={profile.profile_uuid}
              permissions={profile.edit || profile.owner}
              user_uuid={user_uuid}
              owner={profile.owner}
              onProfileRemove={handleProfileRemove}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-1">
        <Pagination>
          <PaginationContent>
            {totalPages > 1 ? (
              <>
                <PaginationItem>
                  <PaginationPrevious
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(currentPage - 1);
                    }}
                  />
                </PaginationItem>
                {Array.from({ length: totalPages }, (_, i) => (
                  <PaginationItem key={i + 1}>
                    <PaginationLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange(i + 1);
                      }}
                      isActive={currentPage === i + 1}
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem>
                  <PaginationNext
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageChange(currentPage + 1);
                    }}
                  />
                </PaginationItem>
              </>
            ) : null}
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
}

export default ListProfiles;
