import React from 'react';
import { Button } from '../../components/ui/button';
import googleLogo from '../../assets/google_button.png'

const GoogleLogin = ({ 
  handleGoogleSuccess,
  text
}) => (
  <Button
    className="w-full px-4 py-3 h-12 mt-6 text-lg text-gray-700 bg-white border border-gray-300 rounded-lg flex items-center justify-center hover:bg-gray-100 focus:outline-none shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
    onClick={handleGoogleSuccess}
  >
    <img
      src={googleLogo}
      alt="Google logo"
      className="h-6 w-6 mr-3"
    />
    {text}
  </Button>
);

export default GoogleLogin;
