import { supabase } from "../lib/supabaseClient";

export const response = {
  success: false,
  status_code: null,
  data: null,
};

export async function saveError(body) {
  const { data, error } = await supabase.from("handle_errors").insert(body);
  if (error) {
    response.success = false;
    response.status_code = 500;
    response.data = error;
    return response;
  }
  response.success = true;
  response.status_code = 200;
  response.data = data;
  return response;
}
