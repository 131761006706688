import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar"
 
const SmallAvatar = ({
  src
}) => {

  // console.log("src", src);
  const placeholder = 'profile.jpeg';

  const imageUrl = src && src.startsWith('https')
    ? src
    : `https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${src || placeholder}?${new Date().getTime()}`;

  // console.log("Image URL", imageUrl);
  return (
    <Avatar>
      <AvatarImage src={imageUrl} alt="@shadcn" />
      <AvatarFallback>-</AvatarFallback>
    </Avatar>
  );
}

export default SmallAvatar;
