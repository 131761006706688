import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from "../lib/supabaseClient";

import { Loading } from '../components/ui/loading';
import { useNavigate } from 'react-router-dom';

export const response = {
  success: false,
  data: null,
  status_code: null
}

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);

  const navigate = useNavigate();
  const schema = process.env.REACT_APP_SCHEME || 'public';

  useEffect(() => {
    setLoading(true);
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setSession(session);
      }
    };
    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange((
      _event,
      session
    ) => {

      if (_event === 'PASSWORD_RECOVERY') {
        // alert('PASSWORD_RECOVERY')
        navigate('/auth/reset-password');
        setIsPasswordRecovery(true);
      }

      setSession(session);
      if (session) {
        getProfile(session.user.id)
          .then(userProfile => {
            setProfile(userProfile);
          }
        );
        setLoading(false);
      }
      else {
        setProfile(null);
        setLoading(false);
      }

    });

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, [navigate]);

  const getSession = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      return true;
    }
    else {
      return false;
    }
  } 

  const getProfile = async (userId) => {
    const { data, error } = await supabase
      .from('user')
      .select(`
        user_uuid,
        name,
        email,
        phone_number,
        state:state_id (
          state_id,
          country_id,
          country:country_id (
          )
        ),
        state,
        avatar_url,
        date_created,
        date_update
      `)
      .eq('user_uuid', userId)
      .single()

    if (error) {
      console.log('error', error);
      return null;
    }

    return data;
  }

const loginGoogle = async () => {
  setLoading(true);
  const currentUrl = `${window.location.origin}/dashboard`;
  let { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: currentUrl,
    }
  });

  console.log(data, error)

  if (error) {
    response.success = false;
    response.data = error;
    response.status_code = 500;
    setLoading(false);
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;
  setLoading(false);
  return response;
}

  const login = async (email, password, remember) => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({ 
      email,
      password
    });
    if (error) {
      response.success = false;
      response.status_code = error.status;
      // console.log(error);
      if (error.message.includes("Email not confirmed")) {
        response.data = "Tu correo electrónico no ha sido confirmado. Por favor, verifica tu correo.";
      } 
      else if (error.message.includes("Invalid login credentials")) {
        response.data = "Correo o contraseña incorrectos. Por favor, inténtalo de nuevo.";
      } 
      else {
        response.data = "Ha ocurrido un error del servidor. Inténtalo de nuevo más tarde.";
      }
      setLoading(false);
      return response;
    } 
    // Dashboard

    // console.log(data.session);

    if (remember) {
    //   localStorage.setItem('supabaseSession', JSON.stringify(data.session));
    }
    // else {
    //   sessionStorage.setItem('supabaseSession', JSON.stringify(data.session));
    // }

    setSession(data.session);
    const userId = data.user.id;

    const profile = await getProfile(userId);

    // console.log(profile.state);

    if (profile) {

      if (profile.state){
        setProfile(profile);
        response.success = true;
        response.data = profile;
        response.status_code = 200;
      }
      else {
        setSession(null);
        response.success = false;
        response.data = "Usuario bloqueado.";
        response.status_code = 403;
      }
    }
    setLoading(false);
    return response;
  }

  const signup = async (data_) => {
    setLoading(true);

    let { data, error } = await supabase
      .rpc('email_exists', { p_params: { email: data_.email, schema: schema } });

    if (data) {
      response.success = false;
      response.data = "El correo electrónico ya está registrado.";
      response.status_code = 400;

      setLoading(false);
      return response;
    }

    if (error) {
      console.log(error)
      response.success = false;
      response.data = "Ha ocurrido un error del servidor. Inténtalo de nuevo más tarde.";
      response.status_code = 500;
      setLoading(false);
      return response;
    }

    ({ data, error } = await supabase.auth.signUp({
      email: data_.email,
      password: data_.password,
      options: {
        data: {
          name: data_.first_name + ' ' + data_.last_name,
          schema: schema,
        },
        emailRedirectTo: `${window.location.origin}/auth?confirm_email=true`
      }
    }));

    if ( error ) {
      response.success = false;
      response.data = "Ha ocurrido un error del servidor. Inténtalo de nuevo más tarde.";
      response.status_code = 500;
    }
    else {
      response.success = true;
      response.data = data;
      response.status_code = 200;
    }
    console.log(data, error)
    setLoading(false);
    return response;
  }

  const logout = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signOut();
    if (error) {
      // setErrorMessage(error.message);
      alert('Logout failed');
    }
    else {
      setSession(null);
      // setProfile(null);
      setLoading(false);
      navigate('/auth');
    }
  }

  const resetPassword = async (email) => {
    setLoading(true);
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/auth/reset-password`
    });
    if (error) {
      response.success = false;
      response.data = "Ha ocurrido un error del servidor. Inténtalo de nuevo más tarde.";
      response.status_code = 500;
      return response;
    }

    response.success = true;
    response.data = data;
    response.status_code = 200;
    setLoading(false);
    return response;
  }

  return (
    <AuthContext.Provider value={{
      session,
      setSession,
      login,
      signup,
      logout,
      profile,
      setProfile,
      loginGoogle,
      resetPassword,
      isPasswordRecovery,
      getSession
    }}>
      <Loading loading={loading} />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
