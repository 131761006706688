import React from 'react';
import { Outlet } from 'react-router-dom';
import { useHeader } from '../contexts/HeaderContext';
import Header from '../components/Header';
import { BottomBar } from '../components/BottonBar';


export function MainLayout() {
  const headerConfig = useHeader();
  const showBottomBar = headerConfig.linkComponentsMobile.length > 0 || headerConfig.actionComponentsMobile.length > 0;
  
  return (
    <div className="w-full h-screen flex flex-col">
      <Header {...headerConfig} />
      <main className={`flex-1 overflow-auto ${showBottomBar?'mb-16':'mb-0'} md:mb-0`}>
        <Outlet />
      </main>
      <BottomBar {...headerConfig} />
    </div>
  );
}

export default MainLayout;
