import React from 'react';

export function BottomBar({ linkComponentsMobile = [], actionComponentsMobile = [], showLinks = true }) {
  return (
    <>
      {showLinks && (
        linkComponentsMobile.length > 0 && actionComponentsMobile.length > 0 && (
        <div className="md:hidden fixed bottom-0 left-0 z-50 w-full shadow-xl h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <div className="w-full h-full flex flex-row items-center justify-evenly space-x-6 ml-auto">
          {linkComponentsMobile.map((LinkComponent, index) => (
            <LinkComponent key={index} />
          ))}
          {actionComponentsMobile.map((ActionComponent, index) => (
            <ActionComponent key={index} />
          ))}
        </div>
      </div>
        )
      )}
    </>
  )
}
