import { supabase } from "../lib/supabaseClient";
import { saveError } from "./saveError";

export const response = {
  success: false,
  data: null,
  status_code: null,
};

export async function update_user(user_uuid, user) {
  const currentDate = new Date().toISOString().split("T")[0];
  user.date_update = currentDate;
  const { country_id, ...userWithoutCountry } = user;
  const { data, error } = await supabase
    .from("user")
    .update(userWithoutCountry)
    .eq("user_uuid", user_uuid);

  if (error) {
    saveError({
      process: "update_user",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function getUser(user_uuid) {
  const { data, error } = await supabase
    .from("user")
    .select(
      `
      user_uuid,
      name,
      email,
      phone_number,
      state:state_id (
        state_id,
        country_id,
        country:country_id (
        )
      ),
      state,
      avatar_url,
      date_created,
      date_update
    `
    )
    .eq("user_uuid", user_uuid)
    .single();

  if (error) {
    saveError({
      process: "get_user",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function UpdatePassword(newPassword) {
  const user = await supabase.auth.getUser();
  const { data, error } = await supabase.auth.updateUser({
    password: newPassword,
  });

  console.log("update password", data, error);

  if (error) {
    await saveError({
      process: "update_password",
      error: error,
      user_uuid: user.data.user.id,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}

export async function add_medallion(medallion, user_uuid) {
  let { data, error } = await supabase
    .from("medallion")
    .select("user_uuid")
    .eq("medallion_uuid", medallion)
    .single();

  console.log(data, error);

  if (error) {
    await saveError({
      process: "add_medallion",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  if (data && data.user_uuid) {
    response.success = false;
    response.data = data;
    response.status_code = 401;
    return response;
  }

  ({ data, error } = await supabase
    .from("medallion")
    .update({ user_uuid: user_uuid })
    .eq("medallion_uuid", medallion));

  console.log(data, error);
  if (error) {
    await saveError({
      process: "add_medallion",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.success = false;
    response.data = error;
    response.status_code = 500;
    return response;
  }

  response.success = true;
  response.data = data;
  response.status_code = 200;

  return response;
}
