import * as React from "react";
import { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input"
import {
  Tabs,
  TabsContent,
} from "../../components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../../components/ui/dialog";
import { upload_multimedia } from "../../lib/supabaseProfile";
import { Loading } from "../../components/ui/loading";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { Card, CardContent } from "../../components/ui/card";
import { Label } from "@radix-ui/react-label";

const maxFileSizeMB = 10;

export function UploadProfilePictureCard({
  user_uuid,
  profile_uuid = null,
  nameFile = "",
  loading,
  setLoading,
  updateLocal,
  open,
  setOpen,
  updateFunction,
  type = "avatar",
  order_max = 1,
  setOrder_max = () => { }
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [messageError, setMessageError] = useState("");
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [youtubeLinks, setYoutubeLinks] = useState([]);
  const [newLink, setNewLink] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const [typeTab, setTypeTab] = useState("");
  const [contentTypeTab, setContentTypeTab] = useState("");
  const [textType, setTextType] = useState("")

  useEffect(() => {
    setContentTypeTab("multimedia")
    if (type === "banner") {
      setActiveTab("banner")
      setTypeTab("banner")
      setTextType("Banner")
    }
    else {
      if (type === "content") {
        setTypeTab("content")
        setTextType("archivos")
      }
      else {
        setTextType("Foto de Perfil")
        setTypeTab("avatar")
      }
      setActiveTab("upload")
    }
  }, [type, open]);


  const totalBanners = 10;

  // console.log(order_max)

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (type === "content") {
      handleMultipleFiles(files);
    } else {
      handleSingleFile(files[0]);
    }
  };

  const handleSingleFile = (file) => {
    if (file && file.size / 1024 / 1024 <= maxFileSizeMB) {
      setMessageError("");
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setImageFile(file);
        console.log(selectedBanner);
        setSelectedBanner(null);
      };
      reader.readAsDataURL(file);
    } else {
      setMessageError(`El archivo debe ser menor a ${maxFileSizeMB}MB`);
    }
  };

  const handleMultipleFiles = (files) => {
    const validFiles = files.filter(file => file.size / 1024 / 1024 <= maxFileSizeMB);

    if (validFiles.length !== files.length) {
      setMessageError(`Algunos archivos superan el tamaño máximo de ${maxFileSizeMB}MB`);
    } else {
      setMessageError("");
    }

    const fileReaders = validFiles.map(file => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ result: reader.result, file });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileReaders).then(filePreviews => {
      setSelectedFiles(validFiles);
      setPreviewImages(filePreviews.map(filePreview => filePreview.result));
    });
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const getCroppedImg = (imageSrc, pixelCrop, fileType, aspect) => {
    const image = new Image();
    image.src = imageSrc;
    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const cropWidth = aspect === 3 / 1 ? pixelCrop.width : Math.min(pixelCrop.width, pixelCrop.height);
        const cropHeight = aspect === 3 / 1 ? pixelCrop.width / aspect : Math.min(pixelCrop.width, pixelCrop.height);

        canvas.width = cropWidth;
        canvas.height = cropHeight;

        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          cropWidth,
          cropHeight
        );

        canvas.toBlob((blob) => {
          resolve(blob);
        }, fileType || "image/jpeg");
      };
    });
  };

  const handleSubirClick = async () => {
    if ((type === "content" && selectedFiles.length === 0 && youtubeLinks.length === 0) ||
      (type !== "content" && !selectedImage)) {
      setMessageError("No se ha seleccionado ningún archivo o enlace");
      return;
    }
    setLoading(true);
    setOpen(false);

    let filesToUpload = [];
    if (type === "content") {
      if (contentTypeTab === "links") {
        filesToUpload = youtubeLinks.map(link => ({ file: link, name: link }));
      } else {
        filesToUpload = selectedFiles.map(file => ({ file, name: file.name, size: file.size }));
      }
    }
    else {
      const croppedImage = await getCroppedImg(selectedImage, croppedAreaPixels, imageFile.type, type === "banner" ? 3 / 1 : 1);
      filesToUpload = [{ file: croppedImage, name: imageFile.name }];
    }

    let order_act = order_max;
    for (const fileData of filesToUpload) {
      if (!fileData.file || !fileData.name) {
        setMessageError("Error al subir el archivo: nombre del archivo no encontrado");
        continue;
      }
      let fileExt = contentTypeTab === "links" ? "txt" : fileData.name.split(".").pop();
      let fileName = "";
      if (nameFile === "") {
        fileName = `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;
      }
      else {
        fileName = nameFile;
      }

      let filePath = "";
      if (profile_uuid) {
        filePath = `${user_uuid}/${profile_uuid}/${fileName}.${fileExt}`;
      }
      else {
        filePath = `${user_uuid}/${fileName}.${fileExt}`;
      }

      let response = null;
      let values = {}
      let publicURL = "";
      // console.log(youtubeLinks);
      if (contentTypeTab === "links") {
        order_act = order_act + 1;
        values = {
          user_uuid: user_uuid,
          profile_uuid: profile_uuid,
          extension: "yt",
          size: 0,
          url: fileData.file,
          type: "link",
          order: order_act,
          is_approved: true,
          is_comment: false,
        }
        response = await updateFunction(values, user_uuid);
        if (response) {
          publicURL = response.data
          updateLocal(publicURL);
        }
        // console.log(response)
      }
      else {
        const data = await upload_multimedia(filePath, fileData.file, true);
        if (data) {
          publicURL = data.data;
          if (data.status_code === 200) {
            if (type === "content") {
              let file_type = ""
              fileExt = fileExt.toLowerCase();
              if (fileExt === "jpeg" || fileExt === "jpg" || fileExt === "png" || fileExt === "gif" || fileExt === "webp" || fileExt === "svg") {
                file_type = "image"
              }
              else if (fileExt === "mp4" || fileExt === "webm" || fileExt === "mov" || fileExt === "MOV" || fileExt === "avi" || fileExt === "wmv" || fileExt === "mkv") {
                file_type = "video"
              }
              else {
                file_type = "link"
              }
              order_act = order_act + 1;
              values = {
                user_uuid: user_uuid,
                profile_uuid: profile_uuid,
                extension: fileExt,
                size: fileData.size / 1024 / 1024,
                url: publicURL,
                type: file_type,
                order: order_act,
                is_approved: true,
                is_comment: false,
              };
              response = await updateFunction(values, user_uuid);
              if (response) {
                console.log("asd----", response)
                publicURL = response.data;
                // console.log("link", publicURL)
              }
            }
            else {
              response = profile_uuid ? await updateFunction(filePath, profile_uuid, user_uuid) : await updateFunction(filePath, user_uuid);
            }
            if (response && response.status_code === 200) {
              updateLocal(publicURL);
              setMessageError("");
            } else {
              setMessageError("Error al subir la imagen");
            }
          }
        }
      }
    }
    setOrder_max(order_act);
    setLoading(false);
  };

  const handleAddLink = () => {
    if (newLink.trim()) {
      setYoutubeLinks([...youtubeLinks, newLink.trim()]);
      setNewLink("");
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedImage(null);
      setImageFile(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setCroppedAreaPixels(null);
      setSelectedBanner(null);
      setSelectedFiles([]);
      setPreviewImages([]);
      setYoutubeLinks([]);
      setNewLink("");
    }
  }, [open]);

  const handleBannerClick = async (url) => {
    setSelectedBanner(url);
    const fileName = url.substring(url.lastIndexOf('/') + 1);
    setLoading(true);
    const response = await updateFunction(fileName, profile_uuid, user_uuid);
    if (response) {
      if (response.status_code !== 200) {
        console.log(response.data);
        setMessageError("Error al subir la imagen");
      }
      else {
        updateLocal(fileName);
      }
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Dialog className="border-0" open={open} onOpenChange={setOpen}>
        <DialogContent className="w-11/12 sm:max-w-[500px] p-6">
          <DialogHeader>
            <DialogTitle className="text-lg font-bold">Subir {textType}</DialogTitle>
          </DialogHeader>
          <div className="">
            <Tabs value={activeTab}>
              <TabsContent value="content">
              </TabsContent>
              <TabsContent value="banner">
                <div className="flex flex-col items-center justify-evenly">
                  <Label>Eliga una Opción</Label>
                  <Button variant="outline" className="cancel-button w-full md:w-1/2 my-2" onClick={() => setActiveTab("upload")}>
                    Subir Foto
                  </Button>
                  <Label>ó</Label>
                  <Button variant="outline" className="cancel-button w-full md:w-1/2 my-2" onClick={() => setActiveTab("template")}>
                    Plantilla Predeterminada
                  </Button>
                </div>
              </TabsContent>
              <TabsContent value="template">
                <>
                  <div className="text-center text-sm text-gray-500">Fondos Predeterminados</div>
                  <div className="relative">
                    <Carousel
                      className="w-full overflow-hidden"
                      opts={{
                        loop: true,
                      }}
                    >
                      <CarouselContent
                        className={`ml-1 flex`}
                      >
                        {Array.from({ length: totalBanners }).map((_, index) => (
                          <CarouselItem key={index} className="px-1 flex-shrink-0 w-full md:w-1/3 lg:w-1/4">
                            <div className="w-full h-full cursor-pointer" onClick={() => handleBannerClick(`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/banner${index + 1}.png`)}>
                              <Card>
                                <CardContent className="flex items-center justify-center p-2">
                                  <img src={`https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/banner${index + 1}.png`} alt={`Banner ${index + 1}`} className="w-full h-24 object-cover rounded" />
                                </CardContent>
                              </Card>
                            </div>
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      <CarouselPrevious className="absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer"  />
                      <CarouselNext className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer" />
                    </Carousel>
                  </div>
                </>
              </TabsContent>
              <TabsContent value="upload">
                <Tabs value={typeTab}>
                  <TabsContent value="content">
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Selecciona el tipo de contenido:
                      </label>
                      <select
                        value={contentTypeTab}
                        onChange={(e) => setContentTypeTab(e.target.value)}
                        className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="multimedia">Fotos y Videos</option>
                        <option value="links">Links de YouTube</option>
                      </select>
                    </div>
                  </TabsContent>
                  <Tabs value={contentTypeTab}>
                    <TabsContent value="links">
                      <div className="flex flex-col space-y-2">
                        <div className="flex space-x-2">
                          <input
                            type="text"
                            value={newLink}
                            onChange={(e) => setNewLink(e.target.value)}
                            placeholder="Agregar link de YouTube"
                            className="block w-full px-4 py-2 border border-gray-300 rounded shadow focus:outline-none focus:ring"
                          />
                          <Button onClick={handleAddLink}>Agregar</Button>
                        </div>
                        <div className="flex flex-wrap space-x-2">
                          {youtubeLinks.map((link, index) => (
                            <div key={index} className="flex items-center space-x-2">
                              <span className="text-gray-700">{link}</span>
                              <Button variant="outline" onClick={() => setYoutubeLinks(youtubeLinks.filter((_, i) => i !== index))}>Eliminar</Button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </TabsContent>
                    <TabsContent value="multimedia">
                      <div className="w-full h-[150px] border-dashed border-2 border-gray-300 rounded flex items-center justify-center cursor-pointer relative overflow-hidden">
                        {!selectedImage && !selectedFiles.length && contentTypeTab !== "links" && (
                          <label className="absolute inset-0 flex items-center justify-center cursor-pointer">
                            <Input
                              type="file"
                              accept={(type === "avatar"||type === "banner")?"image/jpeg,image/png,image/jpg,image/gif,image/webp,image/svg+xml":".mov,image/jpeg,image/png,image/jpg,image/gif,image/webp,image/svg+xml,video/mp4,video/webm,video/*"}
                              multiple={type === "content"}
                              className="absolute inset-0 opacity-0 cursor-pointer"
                              onChange={handleFileChange}
                            />
                            <span className="text-center text-gray-500">Arrastra el archivo aquí o haz clic para seleccionar</span>
                          </label>
                        )}
                        {selectedImage && type !== "content" && (
                          <div className="relative w-full h-full">
                            <Cropper
                              image={selectedImage}
                              crop={crop}
                              zoom={zoom}
                              aspect={type === "banner" ? 3 / 1 : 1}
                              onCropChange={onCropChange}
                              onZoomChange={onZoomChange}
                              onCropComplete={onCropComplete}
                              cropShape={type === "avatar" ? "round" : "rect"}
                              style={{
                                containerStyle: {
                                  width: "100%",
                                  height: "100%",
                                },
                                cropAreaStyle: {
                                  width: "100%",
                                  height: "100%",
                                },
                              }}
                            />
                          </div>
                        )}
                        {selectedFiles.length > 0 && type === "content" && contentTypeTab !== "links" && (
                          <div className="relative w-full h-full flex flex-wrap overflow-y-auto">
                            {selectedFiles.map((file, index) => (
                              file.type.startsWith('image/') ? (
                                <img
                                  key={index}
                                  src={previewImages[index]}
                                  alt={`Preview ${index}`}
                                  className="object-cover w-24 h-24 m-1 rounded"
                                />
                              ) : (
                                <video
                                  key={index}
                                  src={previewImages[index]}
                                  controls
                                  className="object-cover w-24 h-24 m-1 rounded"
                                />
                              )
                            ))}
                          </div>
                        )}
                      </div>
                    </TabsContent>
                  </Tabs>
                </Tabs>
              </TabsContent>
            </Tabs>
            {selectedImage && type !== "content" && (
              <>
                <div className="text-center text-sm text-gray-500">
                  Usa los dedos o la rueda del mouse para hacer zoom y seleccionar una imagen más precisa.
                </div>
              </>
            )}
          </div>
          <div className="flex items-center text-sm justify-between mt-1.5 mb-0 text-red_1">
            {messageError}
          </div>
          {((type === "banner") || (type !== "banner")) &&
            <DialogFooter>
              <Button variant="outline" className="cancel-button" onClick={() => { setOpen(false); }}>Cancelar</Button>
              <Button className="upload-button" onClick={handleSubirClick}>Subir</Button>
            </DialogFooter>
          }
        </DialogContent>
      </Dialog>
      <Loading loading={loading} />
    </>
  );
}
