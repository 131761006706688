import React, { useState, useEffect } from "react";
import { Card } from "../ui/card.jsx";
import { ImageRende } from "../../components/ImageRende";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import MessageAlert from "../ui/message_alert.js";
import { Textarea } from "../ui/textarea.jsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel,
} from "../ui/form.jsx";
import { getUser } from "../../lib/supabaseUser";
import { sendEmailReportar } from "../../lib/sendEmails.js";
import { update_report_comment } from "../../lib/supabaseProfile.js";
import { Label } from "../ui/label.jsx";

const formSchema = z.object({
  description: z.string().min(1, { message: "El comentario es requerido" }),
});

function CardComment({
  comment = null,
  is_edit = true,
  ispublications = false,
  handleDeleteComment,
  handleApproveComment,
  user_uuid,
}) {
  const [anular, setAnular] = useState(false);
  const [reportar, setReportar] = useState(false);
  const [openMessageDelete, setOpenMessageDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [owner, setOwner] = useState(null);

  useEffect(() => {
    const getOwner = async () => {
      console.log(comment);
      if (!comment || ispublications) return;

      const response = await getUser(comment.user_uuid);

      if (response.success) {
        setOwner(response.data);
      }
    };
    getOwner();
  }, [comment, ispublications]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} de ${month}, ${year}`;
  }

  async function handleDelete() {
    if (!reportar) {
      await handleDeleteComment(comment.content_uuid, comment.url);
    }

    setOpenMessageDelete(false);
    setAnular(true);
    if (reportar) {
      form.reset({
        description: "",
      });
      setReportar(false);
      setAnular(false);
    }
  }

  const onSubmit = async () => {
    const response = await update_report_comment(
      comment.content_uuid,
      form.getValues().description,
      user_uuid
    );
    if (!response.success) {
      setMessage("Error al reportar el comentario");
      setOpenMessageDelete(true);
      return;
    }
    await sendEmailReportar(
      form.getValues().description,
      owner.email,
      comment.content_uuid
    );

    setMessage("El comentario ha sido reportado");
    setOpenMessageDelete(true);
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
    },
  });

  if (!comment) return;

  return (
    <>
      <Card className="relative mx-auto my-8 w-11/12 sm:max-w-screen-sm rounded-xl border border-gray-200 bg-white shadow-lg overflow-hidden">
        <div className="flex items-center p-4">
          <ImageRende
            src={ispublications?comment.profile_avatar_url:comment.avatar_url}
            alt="Avatar"
            className="rounded-full border-6 border-white h-12 w-12"
          />
          <div className="ml-2">
            <p className="text-sm text-gray-600 text-left">
              Comentaste el {formatDate(comment.creation_datetime)}
            </p>
            <h3 className="text-lg text-gray-800 font-semibold text-left">
              {comment.name}
            </h3>
          </div>
        </div>
        <div className="px-4 pb-4">
          <p className="text-base text-black text-left whitespace-pre-wrap">
            {comment.description}
          </p>
          {comment.is_reported && (
            <Label className="text-xs" >(Comentrario Reportado)</Label>
          )}
        </div>
        <div className="px-4 py-2">
          <ImageRende
            isComentario={true}
            src={comment.url}
            alt="comment_image"
            className="w-full h-auto rounded-lg"
          />
        </div>
        {/* Heart icon and count */}

        {is_edit && (
          <div className="px-4 py-2 flex items-center justify-between">
            <div className="hidden items-center">
              <button className="flex items-center focus:outline-none text-gray-600 hover:text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <span className="text-lg text-gray-600">12</span>
              </button>
            </div>
            {/* Conditionally render buttons */}
            {!comment.is_approved && !comment.is_reported && (
              <div className="relative flex justify-center space-x-3.5">
                <button
                  onClick={async () => {
                    await handleApproveComment(
                      comment.content_uuid,
                      owner.email,
                      comment
                    );
                  }}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded mx-1 md:mx-0"
                >
                  Aprobar
                </button>
                <Dialog open={anular} onOpenChange={setAnular}>
                  <DialogTrigger asChild>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded mx-1 md:mx-0"
                      onClick={() => setAnular(true)}
                    >
                      Anular
                    </button>
                  </DialogTrigger>
                  <DialogContent className="transform flex max-w-[90%] sm:max-w-[450px] items-center justify-center p-0 rounded-lg">
                    <div className="bg-white p-6 rounded-lg max-w-md">
                      <h2 className="text-xl font-semibold mb-4 text-center">
                        ¿Estás seguro que quieres anularlo?
                      </h2>
                      <p className="text-gray-700 mb-4">
                        Si anulas este comentario, los cambios serán
                        irreversibles. Si consideras que este comentario es
                        inapropiado, por favor haz clic en el botón de reportar.
                      </p>
                      <div className="flex justify-center mb-4">
                        <button
                          onClick={async () => {
                            await handleDeleteComment(
                              comment.content_uuid,
                              comment.url
                            );
                            setAnular(false);
                          }}
                          className="bg-red-600 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                        >
                          Anular
                        </button>
                        <button
                          onClick={() => {
                            setAnular(false);
                            setReportar(true);
                          }}
                          className="bg-red-600 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                        >
                          Reportar
                        </button>
                        <button
                          onClick={() => setAnular(false)}
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                <Dialog open={reportar} onOpenChange={setReportar}>
                  <DialogTrigger asChild>
                    <button
                      className="hidden bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-3 md:py-2 md:px-4 rounded mx-1 md:mx-0"
                      onClick={() => setReportar(true)}
                    >
                      Reportar
                    </button>
                  </DialogTrigger>
                  <DialogContent className="transform flex max-w-[90%] sm:max-w-[450px] items-center justify-center p-0 rounded-lg">
                    <div className="bg-white p-6 rounded-lg max-w-md">
                      <Form {...form}>
                        <form
                          onSubmit={form.handleSubmit(onSubmit)}
                          className="space-y-4"
                        >
                          <div className="grid grid-row-1 gap-4">
                            <FormField
                              control={form.control}
                              name="description"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-base">
                                    Descripción: *
                                  </FormLabel>
                                  <FormControl>
                                    <Textarea
                                      placeholder="Descripción *"
                                      {...field}
                                      className="w-full px-4 py-3 mb-0 h-28 font-medium bg-white text-black text-base focus:outline-none focus:bg-white border border-gray-300 resize-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <div className="flex justify-center mb-4">
                              <button
                                type="submit"
                                className="bg-red-600 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
                              >
                                Reportar
                              </button>
                              <button
                                onClick={() => setReportar(false)}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                              >
                                Cancelar
                              </button>
                            </div>
                          </div>
                        </form>
                      </Form>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            )}
            {comment.is_approved && !comment.is_reported && (
              <div className="flex space-x-3.5">
                <button
                  className="bg-transparent hover:bg-red-600 text-red-600 font-semibold hover:text-white py-2 px-4 border border-red-600 hover:border-transparent rounded"
                  onClick={async () => {
                    setOpenMessageDelete(true);
                    setMessage(
                      "¿Estás seguro que quieres eliminar este comentario?"
                    );
                  }}
                >
                  Eliminar Comentario
                </button>
              </div>
            )}
          </div>
        )}
      </Card>
      {openMessageDelete && (
        <MessageAlert
          open={openMessageDelete}
          setOpen={setOpenMessageDelete}
          message={message}
          messageContinue="Aceptar"
          actionContinue={handleDelete}
          messageCancel="Cancelar"
        />
      )}
    </>
  );
}

export default CardComment;
