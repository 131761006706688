import React, { useEffect, useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  FormLabel
} from "../ui/form";
import { Combobox } from '../ui/combobox';
import { Input } from "../ui/input";
import { Loading } from '../ui/loading';
import { MessageAlert } from '../ui/message_alert';
import {
  get_relationship_states_countries,
  get_states_by_countries,
} from '../../lib/supabaseProfile';
import {
  update_user,
  getUser
} from '../../lib/supabaseUser';

const phoneRegex = /^\d{9,10}$/;

const formSchema = z.object({
  name: z.string().min(1, { message: "El nombre y apellido es requerido" }),
  phone_number: z.preprocess((val) => String(val), z.union([
    z.string().regex(phoneRegex, { message: "Ingrese un número de teléfono válido" }),
    z.string().length(0).optional()
  ])),
  country_id: z.number().int().min(1, { message: "El país es requerido" }),
  state_id: z.number().int().min(1, { message: "La región es requerida" }),
});

function EditUserData() {
  const { profile, setProfile } = useAuth();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    countries: [],
    states: [],
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phone_number: "",
      country_id: 0,
      state_id: 0,
    },
  });

  const { reset, watch, setValue } = form;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await get_relationship_states_countries();
      if (response.success) {
        setData(prevData => ({ ...prevData, countries: response.data.countries }));
      }
      setLoading(false);
    };

    const fetchUserData = async () => {
      if (profile) {
        const response = await getUser(profile.user_uuid);
        if (response.success) {
          const userData = {
            ...response.data,
            country_id: response.data.state ? response.data.state.country_id ?? 0 : 0,
            state_id: response.data.state ? response.data.state.state_id ?? 0 : 0,
          };

          if (userData.phone_number === null) {
            userData.phone_number = "";
          }

          reset(userData);
        }
      }
    };

    fetchData();
    fetchUserData();
  }, [profile, reset]);

  const country_id = watch("country_id");

  useEffect(() => {
    const fetchStates = async () => {
      if (country_id) {
        setLoading(true);
        const response = await get_states_by_countries(country_id);
        if (response.success) {
          setData(prevData => ({ ...prevData, states: response.data }));
        }
        setLoading(false);
      }
    };

    fetchStates();
  }, [country_id]);

  const onSubmit = async (data) => {
    if (data.phone_number === "") {
      data.phone_number = null;
    }
    setOpen(true);
    const response = await update_user(profile.user_uuid, data);
    if (response.success) {
      setMessage("Datos guardados correctamente");
      setProfile(prevProfile => ({ ...prevProfile, ...data }));
    } else {
      console.log(response.data)
      setMessage("Error al guardar los datos");
    }
  };

  const handleCountryChange = (val) => {
    setValue("country_id", val);
    setValue("state_id", 0);
  };

  return (
    <>
      <div className="w-full flex flex-col items-center m-0">
        <div className="w-full max-w-full md:max-w-5xl bg-white relative text-center md:text-left md:rounded-lg px-0 sm:px-10">
          <div className="md:container w-full max-w-full p-0 m-0 md:mx-auto">
            <div className="bg-white px-4 py-6 w-full max-w-full m-0 md:mx-full">
              <h1 className="text-lg font-semibold text-left">Detalles Personales</h1>
              <p className="text-sm text-red_1 text-left mt-2 mb-4"> Los campos que tienen asterisco(*) son obligatorios.</p>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 w-full max-w-full">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Nombre y apellido: *</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Nombre y apellido *" {...field}
                            className="w-full max-w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phone_number"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-base">Telefono:</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Telefono" {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-gray-300 bg-white"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="country_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">País: *</FormLabel>
                          <FormControl>
                            <Combobox
                              label="País"
                              data={data.countries}
                              valueField="country_id"
                              keyField="name_es"
                              value={field.value}
                              onChange={handleCountryChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="state_id"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-base">Región: *</FormLabel>
                          <FormControl>
                            <Combobox
                              label="Región"
                              className="text-black"
                              data={data.states}
                              valueField="state_id"
                              keyField="name"
                              value={field.value}
                              onChange={(val) => {
                                field.onChange(val);
                              }}
                            >
                            </Combobox>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Button
                    type="submit"
                    className="bg-red_1 hover:bg-red-700 mt-2 text-white font-bold py-2 px-4 rounded"

                  >
                    Guardar
                  </Button>
                </form>
              </Form>
              <MessageAlert
                open={open}
                setOpen={setOpen}
                message={message}
                messageContinue="Aceptar"
              />
            </div>
          </div>
        </div>
      </div>
      <Loading loading={loading} />
    </>
  )
}

export default EditUserData;
