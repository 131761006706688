import React, { useEffect, useState } from "react";
import {
  delete_comment,
  get_comments_by_owner,
  get_comments_by_profile,
  update_approved_comment,
} from "../../lib/supabaseProfile";
import CardComment from "./CardComments";
import MessageAlert from "../ui/message_alert";
import { sendEmailConfirmation } from "../../lib/sendEmails";
import { Label } from "../ui/label";
import AddComment from "./AddComment";

function ListComments({
  user_uuid = null,
  profile_uuid = null,
  is_edit = true,
  ispublications = false,
  profile=null,
  value=null,
  open,
  setOpen,
}) {
  const [comments, setComments] = useState([]);
  const [publications, setPublications] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");

  const handleButtonClick = () => {
    setOpenMessage(false);
  };
  useEffect(() => {
    const getPublicationsByUser = async () => {
      if(!user_uuid || !ispublications) return;
      const response = await get_comments_by_owner(user_uuid);
      if (response.success) {
        if (response.data == null) {
          setPublications([]);
          return;
        }
        setPublications(response.data);
      } else {
        setPublications([]);
      }
    }
    getPublicationsByUser();
  },[user_uuid, ispublications]);

  useEffect(() => {
    const getCommentsByProfile = async () => {
      if (!profile_uuid) return;

      const response = await get_comments_by_profile(profile_uuid, user_uuid);
      if (response.success) {
        if (response.data == null) {
          setComments([]);
          return;
        }
        setComments(response.data);
      } else {
        setComments([]);
      }
    };
    getCommentsByProfile();
  }, [profile_uuid, user_uuid]);

  const handleDeleteComment = async (content_uuid, url) => {
    const response = await delete_comment(content_uuid, url, user_uuid);
    if (!response.success) {
      setOpenMessage(true);
      setMessage("Error al eliminar el comentario");
    }
    const newComments = comments
      .filter((comment) => comment.content_uuid !== content_uuid)
      .sort(
        (a, b) => new Date(b.creation_datetime) - new Date(a.creation_datetime)
      );
    setComments(newComments);
  };

  const handleApproveComment = async (content_uuid, email, content) => {
    const { data, success } = await update_approved_comment(
      content_uuid,
      user_uuid
    );
    if (!success) {
      console.error("Error approving comment", data);
      setOpenMessage(true);
      setMessage("Error al aprobar el comentario");
    }

    if (success) {
      await sendEmailConfirmation(email, content);
      setOpenMessage(true);
      setMessage("Comentario Aprobado");
    }

    const newComments = comments.map((comment) => {
      if (comment.content_uuid === content_uuid) {
        return { ...comment, is_approved: true };
      }
      return comment;
    });
    setComments(newComments);
  };

  return (
    <div className="flex flex-col items-center pt-2">
      {!ispublications && !is_edit && comments.length >= 0 && (
        <AddComment
          user_uuid={user_uuid}
          user={profile}
          profile_uuid={profile_uuid}
          mediallon={value}
          open={open}
          setOpen={setOpen}
        />
      )}
      {ispublications && publications.length === 0 && (
        <Label className="my-4">No hay ninguna publicación</Label>
      )}
      {!ispublications && comments.length === 0 && (
        <Label className="my-4">No hay ningún comentario para este perfil</Label>
      )}
      <ul>
        {comments.length > 0 &&
          comments.map((comment) => (
            <li key={comment.content_uuid}>
              {!is_edit && comment.is_approved && (
                <CardComment
                  comment={comment}
                  is_edit={is_edit}
                  user_uuid={user_uuid}
                  handleDeleteComment={handleDeleteComment}
                  handleApproveComment={handleApproveComment}
                />
              )}
              {is_edit && (
                <CardComment
                  comment={comment}
                  is_edit={is_edit}
                  user_uuid={user_uuid}
                  handleDeleteComment={handleDeleteComment}
                  handleApproveComment={handleApproveComment}
                />
              )}
            </li>
          ))
        }
        {
          publications.length > 0 && 
          publications.map(
            (publication) => (
              <li key={publication.content_uuid}>
                <CardComment
                    ispublications={true} 
                    comment={publication}
                    is_edit={false}
                    user_uuid={user_uuid}
                    handleDeleteComment={handleDeleteComment}
                    handleApproveComment={handleApproveComment}
                  />
              </li>
            )
          )
        }
        
      </ul>
      {message.length > 0 && (
        <MessageAlert
          open={openMessage}
          setOpen={setOpenMessage}
          message={message}
          messageContinue="Aceptar"
          actionContinue={handleButtonClick}
        />
      )}
    </div>
  );
}

export default ListComments;
