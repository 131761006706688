const RESEND_API_KEY = 're_C5Z4JG6X_4XvYzZ2qWTqM5DTZRuo8FyJQ'

export async function sendEmailConfirmation(to, comentario) {
  const dateSplit = comentario.creation_datetime.split('T')[0]
  const date = `${dateSplit.split('-')[2]}/${dateSplit.split('-')[1]}/${dateSplit.split('-')[0]}`

  try {
    const response = await fetch('https://qhnnmibkmmbotrxqjmbs.supabase.co/functions/v1/send-email', {
      method: 'POST', // Ajusta el método según lo que requiera tu función
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${RESEND_API_KEY}`
        // Aquí podrías incluir cualquier otro header necesario, como Authorization si se requiere
      },
      body: JSON.stringify({
        from: 'Latido Eterno <notificaciones@latidoeterno.com>',
        to: [to], // Ajusta según sea necesario
        subject: 'Confirmación de Comentario Aprobado',
        html: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
        .container {
            background-color: #ffffff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            text-align: center;
        }
        .header {
            font-size: 24px;
            margin-bottom: 20px;
            color: #333333;
        }
        .image {
            max-width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
        .text {
            font-size: 16px;
            color: #666666;
            margin-bottom: 20px;
        }
        .button {
            background-color: #ffffff;
            color: #ff0000;
            padding: 10px 20px;
            text-decoration: none;
            border: 2px solid #ff0000;
            border-radius: 4px;
            font-size: 16px;
            display: inline-block;
        }
        .button:hover {
            border-color: #000000;
        }
        .content {
          height: 100%;
          displayu: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }
        .span{
          font-size: 16px;
          color: #333333;
          margin-bottom: 20px;
        }
          .fecha {
            font-size: 16px;
            color: #333333;
          }
    </style>
</head>
<body>
    <div class="container">
        <h2 class="header">Confirmación de Comentario Aprobado</h2>
        <img class="image" src="https://latidoeterno.com/cdn/shop/files/503562170248725610-d930bb72-f572-4241-9559-aa86d5c54bc2.png?v=8066684750037235628" alt="Latido Eterno Logo">
        <p class="text">Su comentario ha sido aprobado</p>
        <div class="content">
          <span class="span">Descripcion: ${comentario.description}</span>
          <img class="image" style="margin-top=20px" alt="imagen_comentario" src="https://qhnnmibkmmbotrxqjmbs.supabase.co/storage/v1/object/public/multimedia/${comentario.url}"/>
          <p class="fecha">fecha: ${date} </p>
        </div>
    </div>
    <script>
    </script>
</body>
</html>`,
      }),
    });

    console.log('Respuesta de la función:', response);
    const data = await response.json();
    console.log('Respuesta de la función:', data);
    if (!response.ok) {
      throw new Error('Error al enviar el correo');
    }

  } catch (error) {
    console.error('Error al llamar a la función:', error.message);
  }
}

const LIST_EMAILS = [
  'admin@latidoeterno.com',
  'renzofernandezg.rf@gmail.com',
  'angel456mora123@gmail.com'
];

export async function sendEmailReportar(description,email, comment_uuid) {

  try {
    const response = await fetch('https://qhnnmibkmmbotrxqjmbs.supabase.co/functions/v1/send-email', {
      method: 'POST', // Ajusta el método según lo que requiera tu función
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${RESEND_API_KEY}`
        // Aquí podrías incluir cualquier otro header necesario, como Authorization si se requiere
      },
      body: JSON.stringify({
        from: 'Latido Eterno <notificaciones@latidoeterno.com>',
        to: [...LIST_EMAILS, email], // Ajusta según sea necesario
        subject: 'Confirmación de comentario reportado',
        html: `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
        .container {
            background-color: #ffffff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            text-align: center;
        }
        .header {
            font-size: 24px;
            margin-bottom: 20px;
            color: #333333;
        }
        .image {
            max-width: 100%;
            height: auto;
            margin-bottom: 20px;
        }
        .text {
            font-size: 16px;
            color: #666666;
            margin-bottom: 20px;
        }
        .button {
            background-color: #ffffff;
            color: #ff0000;
            padding: 10px 20px;
            text-decoration: none;
            border: 2px solid #ff0000;
            border-radius: 4px;
            font-size: 16px;
            display: inline-block;
        }
        .button:hover {
            border-color: #000000;
        }
        .content {
          height: 100%;
          displayu: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }
        .span{
          font-size: 16px;
          color: #333333;
          margin-bottom: 20px;
        }
          .fecha {
            font-size: 16px;
            color: #333333;
          }
    </style>
</head>
<body>
    <div class="container">
        <h2 class="header">Confirmación de Comentario Reportado</h2>
        <img class="image" src="https://latidoeterno.com/cdn/shop/files/503562170248725610-d930bb72-f572-4241-9559-aa86d5c54bc2.png?v=8066684750037235628" alt="Latido Eterno Logo">
        <p class="text">comentario uuid: ${comment_uuid}</p>
        <p>descripción: ${description}</>
    </div>
    <script>
    </script>
</body>
</html>`,
      }),
    });

    console.log('Respuesta de la función:', response);
    const data = await response.json();
    console.log('Respuesta de la función:', data);
    if (!response.ok) {
      throw new Error('Error al enviar el correo');
    }

  } catch (error) {
    console.error('Error al llamar a la función:', error.message);
  }
}