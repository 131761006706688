import React from 'react';
import PropTypes from 'prop-types';
import logoh from '../assets/logoh.png';

export function Header({ linkComponents = [], actionComponents = [], showLinks = true }) {
  const shouldShowLinks = showLinks && (linkComponents.length > 0 || actionComponents.length > 0);

  return (
    <header className="w-full h-14 md:h-20 flex justify-center md:justify-between items-center bg-white fixed top-0 z-50 shadow-md px-4 md:px-20">
      <div className={`flex flex-grow ${shouldShowLinks ? 'justify-center md:justify-start' : 'justify-center'}`}>
        <a href="https://www.latidoeterno.com/">
          <img src={logoh} alt="Logo" className="h-11 md:h-16" />
        </a>
      </div>
      {shouldShowLinks && (
        <div className="hidden md:flex items-center space-x-6 ml-auto">
          {linkComponents.map((LinkComponent, index) => (
            <LinkComponent key={index} />
          ))}
          {actionComponents.map((ActionComponent, index) => (
            <ActionComponent key={index} />
          ))}
        </div>
      )}
    </header>
  );
}

Header.propTypes = {
  linkComponents: PropTypes.arrayOf(PropTypes.elementType),
  actionComponents: PropTypes.arrayOf(PropTypes.elementType),
  showLinks: PropTypes.bool,
};

export default Header;
