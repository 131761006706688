import React, { useState, useEffect } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  // AlertDialogTitle,
} from "../../components/ui/alert-dialog";

export function MessageAlert({
  open,
  setOpen,
  message = "",
  messageCancel = null,
  messageContinue = null,
  actionContinue = () => {},
}) {

  const [hiddenCancel, setHiddenCancel] = useState("hidden");
  const [hiddenContinue, setHiddenContinue] = useState("hidden");

  useEffect(() => {
    if (messageCancel) {
      setHiddenCancel("");
    }
    if (messageContinue) {
      setHiddenContinue("");
    }
  }, [messageCancel, messageContinue]);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="w-11/12 sm:max-w-md text-center">
        <div className="grid gap-4 py-5">
          <div className="flex flex-col justify-between text-center text-lg">
            {message}
          </div>
        </div>
        <AlertDialogFooter className="flex flex-row justify-evenly items-center">
          <AlertDialogCancel
            className={`mt-0 rounded bg-red_1 text-white hover:bg-black hover:text-white ${hiddenCancel}`}
            onClick={() => setOpen(false)}
          >
            {messageCancel}
          </AlertDialogCancel>
          <AlertDialogAction
            className={`mt-0 rounded bg-red_1 text-white hover:bg-black hover:text-white ${hiddenContinue}`}
            onClick={() => {
              actionContinue();
            }}
          >
            {messageContinue}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default MessageAlert;
