import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isExternalLink } from '../../lib/isExternalLink';

import { Button } from './button';

export function LinkButton({
  href,
  iconClass = null,
  label,
  className = "",
  isMobile = false,
}) {

  const isExternal = isExternalLink(href);

  const ButtonContent = () => (
    <div className={`flex ${isMobile? 'flex-col items-center':'flex-row items-baseline'}  justify-center`}>
      {iconClass && <i className={`${iconClass} leading-none align-bottom`}></i>}
      <span className="leading-none align-bottom">{label}</span>
    </div>
  );

  if (isExternal) {
    return (
      <a href={href}>
        <Button className={`flex items-center justify-center text-lg ${className}`}>
          <ButtonContent />
        </Button>
      </a>
    );
  }

  return (
    <Link to={href}>
      <Button className={`flex items-center justify-center text-lg ${className}`}>
        <ButtonContent />
      </Button>
    </Link>
  );
}

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  label: PropTypes.string.isRequired,
};
