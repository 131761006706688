import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Auth from "./pages/Auth";
import SignUp from "./pages/SignUp";
import ChangePassword from "./components/Auth/ChangePassword";
import Dashboard from "./pages/Dashboard";
//import ProfileTestQR from './pages/ProfileTestQR';
import { HeaderProvider } from "./contexts/HeaderContext";
import { AuthProvider } from "./contexts/AuthContext";

import MainLayout from "./layouts/MainLayout";

import NotFound from "./components/NotFound";

import ProtectedRoute from "./components/ProtectedRoute";

import CreateProfile from "./components/Dashboard/CreateProfile";

import EditProfile from "./components/Dashboard/EditProfile";
import Profile from "./pages/Profile";

function App() {
  return (
    <Router>
      <AuthProvider>
        <HeaderProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/auth" />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/register" element={<SignUp/>} />
            <Route path="/auth/reset-password" element={<ChangePassword />} />
            <Route element={<MainLayout />}>
              <Route path="/Perfil/:value" element={<Profile />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route path="*" element={<NotFound />} />
              <Route path="/dashboard/create" element={<CreateProfile />} />
              <Route path="/dashboard/edit/:uuid" element={<EditProfile />} />
            </Route>
          </Routes>
        </HeaderProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
