import React, { useState, useEffect } from "react";
import CardMediaContent from "./CardMediaContent";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { update_order_image } from "../../lib/supabaseImages";

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="w-full h-full flex items-center justify-center cursor-grab"
    >
      {props.children}
    </div>
  );
}

export function ListMediaContents({
  user_uuid = null,
  mediaContents = [],
  setItems = () => {},
  editMode = false,
  isEdit = false,
  profile_uuid = null,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [currentMediaContents, setCurrentMediaContents] = useState([]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const mediaContentsPerPage = 9;
    const indexOfLastMediaContent = currentPage * mediaContentsPerPage;
    const indexOfFirstMediaContent = indexOfLastMediaContent - mediaContentsPerPage;
    const validMediaContents = mediaContents || [];
    setCurrentMediaContents(
      validMediaContents.slice(indexOfFirstMediaContent, indexOfLastMediaContent)
    );
    console.log("\n\n\ncurrentMediaContent",currentPage,"aaaaaaa",validMediaContents.slice(indexOfFirstMediaContent, indexOfLastMediaContent));
  }, [currentPage, mediaContents]);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 40,
      },
    })
  );
  
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) {
      return;
    }

    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.order === active.id);
      const newIndex = items.findIndex((item) => item.order === over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      newItems.map(async (item, index) => {
        await update_order_image(item.url, index + 1);
      });
      return newItems;
    });
  };

  const totalPages = Math.ceil(mediaContents.length / 9);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    console.log("aaaaaaa",pageNumber,"------->",currentMediaContents[pageNumber]);
    setCurrentPage(pageNumber);
  };

  if (mediaContents.length === 0) {
    return <h1 className="text-center text-xl mt-10">No media contents available</h1>;
  }

  return (
    <>
      {editMode && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={mediaContents.map((item) => item.order)}
            strategy={rectSortingStrategy}
          >
            <div
              className={`grid gap-4 ${
                isMobile ? "grid-cols-2" : "grid-cols-3"
              } px-4`}
            >
              {mediaContents.map((mediaContent) => (
                <div key={mediaContent.order} className="aspect-square">
                  <SortableItem key={mediaContent.order} id={mediaContent.order}>
                    <CardMediaContent
                      user_uuid={user_uuid}
                      description={mediaContent.description}
                      url={mediaContent.url}
                      type={mediaContent.type}
                      order={mediaContent.order}
                      content_uuid={mediaContent.content_uuid}
                      contents={currentMediaContents}
                      profile_uuid={profile_uuid}
                      setItems={setCurrentMediaContents}
                    />
                  </SortableItem>
                </div>
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}
      {!editMode && (
        <>
          <div className={`grid gap-4 ${isMobile ? "grid-cols-2" : "grid-cols-3"} px-4`}>
            {currentMediaContents.map((mediaContent, index) => (
              <div key={index} className="aspect-square p-0 bg-white rounded-lg shadow-md overflow-hidden">
                <CardMediaContent
                  user_uuid={user_uuid}
                  description={mediaContent.description}
                  url={mediaContent.url}
                  type={mediaContent.type}
                  order={mediaContent.order}
                  content_uuid={mediaContent.content_uuid}
                  editMode={isEdit}
                  profile_uuid={profile_uuid}
                  setItems={setItems}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-4">
            <Pagination>
              <PaginationContent>
                {totalPages > 1 ? (
                  <>
                    <PaginationItem>
                      <PaginationPrevious
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                      />
                    </PaginationItem>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <PaginationItem key={i + 1}>
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(i + 1);
                          }}
                          isActive={currentPage === i + 1}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem>
                      <PaginationNext
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                      />
                    </PaginationItem>
                  </>
                ) : null}
              </PaginationContent>
            </Pagination>
          </div>
        </>
      )}
    </>
  );
}

export default ListMediaContents;
