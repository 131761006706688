import React, { useState, useEffect } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Label } from '../../components/ui/label';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { UpdatePassword } from '../../lib/supabaseUser';

const formSchema = z.object({
  password: z.string().min(6, { message: "La contraseña debe tener al menos 6 caracteres" }),
  confirmPassword: z.string().min(6, { message: "La confirmación de contraseña debe tener al menos 6 caracteres" })
}).refine(data => data.password === data.confirmPassword, {
  message: "Las contraseñas no coinciden",
  path: ["confirmPassword"],
});

export function ChangePassword() {
  const { isPasswordRecovery } = useAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: ""
    },
  });

  useEffect(() => {
    if (!isPasswordRecovery) {
      navigate("/auth");
    }
  }, [isPasswordRecovery, navigate]);

  const onSubmit = async (data) => {
    const response = await UpdatePassword( data.password );
    if (response.status_code !== 200) {
      setMessage("Error al actualizar la contraseña.");
    } else {
      navigate('/auth')
      setMessage("Contraseña actualizada exitosamente.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-700">Cambiar Contraseña</h2>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <Label className="block mb-2 text-sm text-gray-600">Nueva Contraseña</Label>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Nueva Contraseña"
                      {...field}
                      className="w-full px-4 py-2 mt-1 text-gray-700 bg-gray-200 border-0 rounded-md focus:bg-white focus:ring-2 focus:ring-indigo-400 focus:outline-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <Label className="block mb-2 text-sm text-gray-600">Confirmar Contraseña</Label>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Confirmar Contraseña"
                      {...field}
                      className="w-full px-4 py-2 mt-1 text-gray-700 bg-gray-200 border-0 rounded-md focus:bg-white focus:ring-2 focus:ring-indigo-400 focus:outline-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-between mb-4 text-red-500">
              {message}
            </div>
            <Button
              type="submit"
              className="w-full px-4 py-2 text-lg text-white bg-red_1 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              Actualizar Contraseña
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

export default ChangePassword;
