import React, { useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form"
import { Input } from "../../components/ui/input"
import { Checkbox } from "../../components/ui/checkbox"
import { Label } from '../../components/ui/label';

import eye from '../../assets/ojo.png';
import eyeOff from '../../assets/ojocerrado.png';
import { useAuth } from '../../contexts/AuthContext';

import { MessageAlert } from '../ui/message_alert';
import { useNavigate } from 'react-router-dom';

import  GoogleLogin  from "./GoogleLogin";

const formSchema = z.object({
  first_name: z.string().min(1, { message: "El nombre es requerido" }),
  last_name: z.string().min(1, { message: "El apellido es requerido" }),
  email: z.string().email({ message: "Ingrese un correo válido" }),
  password: z.string().min(6, { message: "La contraseña debe tener al menos 6 caracteres" }),
  re_password: z.string().min(6, { message: "La contraseña debe tener al menos 6 caracteres" }),
  terms: z.literal(true, { errorMap: () => ({ message: "Debe aceptar los términos y condiciones" }) }),
}).refine((data) => data.password === data.re_password, {
  message: "Las contraseñas no coinciden",
  path: ["re_password"],
});

export function Register() {

  const { signup, loginGoogle } = useAuth();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false)
  const [showRePassword, setShowRePassword] = useState(false)
  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [message, setMessage] = useState("");

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      re_password: "",
      terms: false,
    },
  });

  const handleGoogleSuccess = async () => {
    const response = await loginGoogle();
    if (response) {
      if (response.status_code !== 200) {
        setMessage(response.data)
      }
    }
  }
  const onSubmit = async(data) => {
    const response = await signup(data);
    if (response) {
      if (response.status_code !== 200) {
        setMessageError(response.data)
      }
      else{
        setMessage("Por favor valida tu correo");
        setOpen(true);
      }
    }
  }

  
  const togglePasswordVisibility = (setFunc) => {
    return () => {
      setFunc(prevState => !prevState);
    };
  };

  const handleButtonClick = () => {
    navigate('/dashboard');
  }

  return (
    <div>
      <GoogleLogin 
        handleGoogleSuccess={handleGoogleSuccess}
        text="Registrarse con Google"
      />
      <p className="mt-4 text-sm text-black text-center">
        <span className="text-lg text-black font-medium">¡Bienvenido!</span>
        <br />
        <span className="text-black font-medium">Por favor completa los campos para registrarte.</span>
      </p>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem className="mt-0">
                <FormControl>
                  <Input
                    placeholder="Nombres" {...field}
                    className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                  />
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem className="mt-0">
                <FormControl>
                  <Input
                    placeholder="Apellidos" {...field}
                    className="w-full px-4 py-3 mt-1.5 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                  />
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="mt-0">
                <FormControl>
                  <Input
                    placeholder="Email" {...field}
                    className="w-full px-4 py-3 mt-1.5 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                  />
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative w-full">
                    <Input 
                      type={showPassword ? "text" : "password"} 
                      placeholder="Contraseña" {...field}
                      className="w-full px-4 py-3 mt-1.5 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-6 flex items-center focus:outline-none"
                      onClick={togglePasswordVisibility(setShowPassword)}
                    >
                    <img
                      src={`${showPassword ? eyeOff : eye}`}
                      alt={showPassword ? "Ocultar Contraseña" : "Mostrar Contraseña"}
                      className="h-6 w-6 text-gray-600"
                    />
                    </button>
                  </div>
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="re_password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative w-full">
                    <Input 
                      type={showRePassword ? "text" : "password"} 
                      placeholder="Confirmar contraseña" {...field}
                      className="w-full px-4 py-3 mt-1.5 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-6 flex items-center focus:outline-none"
                      onClick={togglePasswordVisibility(setShowRePassword)}
                    >
                    <img
                      src={`${showRePassword ? eyeOff : eye}`}
                      alt={showRePassword ? "Ocultar Contraseña" : "Mostrar Contraseña"}
                      className="h-6 w-6 text-gray-600"
                    />
                    </button>
                  </div>
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="terms"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex items-center mb-1 mt-3">
                    <Checkbox 
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="terms"
                    />
                    <Label 
                    htmlFor="terms" 
                    className="pl-2"
                    >
                      <a 
                        href="https://latidoeterno.com/policies/terms-of-service" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-red-500 underline"
                      >
                        Acepto los términos y condiciones
                      </a>
                    </Label>
                  </div>
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <div className="flex items-center text-sm justify-between mt-1.5 mb-0 text-red_1">
            {messageError}
          </div>
          <Button 
            type="submit" 
            className="w-full px-4 py-3 h-12 mt-2 text-lg text-white bg-red_1 rounded-lg hover:bg-black focus:outline-none"
          >
            Ingresar</Button>
        </form>
      </Form>
      <MessageAlert 
        open={open}
        setOpen={setOpen}
        message={message}
        messageContinue="Aceptar"
        actionContinue={handleButtonClick}
      />
    </div>
  )
}

export default Register;
