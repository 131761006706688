import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../components/ui/loading';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabaseClient';


const ProtectedRoute = () => {
  const { session, loading, setSession } = useAuth();
  const [urlTokenProcessed, setUrlTokenProcessed] = useState(false);
  const [urlTokenError, setUrlTokenError] = useState(false);

  useEffect(() => {
    const processUrlToken = async () => {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const refreshToken = hashParams.get('refresh_token');

      // localStorage.setItem('confirm_email', urlParams);
      if (accessToken && refreshToken) {
        const { data, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        if (error) {
          console.error('Error setting session with URL tokens:', error);
          setUrlTokenError(true);
        } 
        else {
          setSession(data.session);
        }
        // VERIFICAR CLEAR
        
        // window.history.replaceState({}, document.title, '/dashboard');
      }

      setUrlTokenProcessed(true);
    };

    processUrlToken();
  }, [setSession]);

  if (loading || !urlTokenProcessed) {
    return <Loading loading={true} />;
  }

  if (urlTokenError) {
    return <Navigate to="/auth" />;
  }

  if (!session) {
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
