/**
* This code was generated by v0 by Vercel.
* @see https://v0.dev/t/Z956b6i7k7g
* Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
*/

/** Add fonts into your Next.js project:

import { Inter } from 'next/font/google'

inter({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/

// <div className="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
//       <div className="flex flex-col items-center space-y-4">
//         <div className="animate-spin rounded-full border-4 border-gray-300 border-t-red-600 h-12 w-12" />
//         <p className="text-gray-600">Cargando...</p>
//       </div>
//     </div>

export function Loading({
  loading = false,
}) {
  return (
    (loading &&
    <div className="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75 z-50">
      <div className="flex h-screen w-full items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <div
            className="animate-spin rounded-full border-4 border-gray-300 border-t-red_1 h-12 w-12" />
          <p className="text-gray-500 dark:text-gray-400">Cargando ...</p>
        </div>
      </div>
    </div>
    )
  );
}
