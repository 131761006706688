import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";
import { Button } from "../../components/ui/button";
import ListUsers from "../../components/Dashboard/ListUsers";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "../../components/ui/input";
import { MessageAlert } from "../ui/message_alert";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form";

import { insert_share_profile } from "../../lib/supabaseProfile";

const formSchema = z.object({
  email: z.string().email({ message: "Ingrese un correo valido" }),
});

const OwnerProfile = ({
  user_uuid = null,
  profile_uuid = null,
  owner = null,
  users = [],
}) => {
  const [admins, setAdmins] = useState([]);
  const [message, setMessage] = useState("");
  const [pending, setPending] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  // console.log("...", admins);
  useEffect(() => {
    if (users.length) {
      const admins = users.filter((user) => user.state === true);
      const pending = users.filter((user) => user.state === false);
      setAdmins(admins);
      setPending(pending);
    }
  }, [users]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });
  //
  console.log(owner);
  // console.log(users);

  const onSubmit = async (data) => {
    const response = await insert_share_profile(
      profile_uuid,
      data.email,
      user_uuid
    );
    // console.log("------", response)
    if (response.success) {
      setOpen(false);
      setAdmins((prevAdmins) => [...prevAdmins, response.data]);
      form.reset();
      setMessageSuccess("Perfil compartido correctamente");
      setOpenSuccess(true);
    } else if (response.status_code === 404) {
      setMessage(
        "El usuario no existe, por favor solicitar que se cree una cuenta."
      );
    } else if (response.status_code === 409) {
      setMessage("El usuario ya ha sido invitado");
    } else {
      setMessage("Error al invitar usuario");
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setMessage("");
    form.reset();
  };

  return (
    <>
      <Tabs defaultValue="admins" className="w-full">
        <div className="flex items-center justify-between w-full px-0 py-4 sm:p-4">
          <TabsList className="flex">
            <TabsTrigger
              value="admins"
              className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
            >
              Usuarios agregados al perfil
            </TabsTrigger>
            {/*
            <TabsTrigger
              value="pending"
              className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
            >
              Invitaciones pendientes
            </TabsTrigger>
            */}
          </TabsList>
          <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
              <Button className="px-2 mr-2 sm:mr-0 sm:px-4 py-2 bg-red_1 text-white font-semibold rounded-md hover:bg-black">
                <p className="hidden sm:block">Compartir perfil</p>
                <i className="sm:hidden fas fa-user-plus text-xl" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="w-11/12 sm:w-full sm:max-w-lg">
              <AlertDialogTitle>Invitar usuario</AlertDialogTitle>
              <AlertDialogDescription>
                Introduzca el correo electrónico del usuario que desea invitar
              </AlertDialogDescription>

              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4"
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="mt-0">
                        <FormControl>
                          <Input
                            placeholder="Email"
                            {...field}
                            className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                          />
                        </FormControl>
                        <FormMessage style={{ marginTop: 0 }} />
                      </FormItem>
                    )}
                  />
                  <AlertDialogFooter>
                    <div className="flex items-center text-sm justify-between mb-1 text-red_1">
                      {message}
                    </div>
                    <AlertDialogCancel className="mr-2" onClick={handleCancel}>
                      Cancel
                    </AlertDialogCancel>
                    <Button
                      type="submit"
                      className="px-4 py-3 h-9 mt-2 text-base text-white bg-red_1 rounded-lg hover:bg-black focus:outline-none"
                    >
                      Compartir
                    </Button>
                  </AlertDialogFooter>
                </form>
              </Form>
            </AlertDialogContent>
          </AlertDialog>
        </div>
        <TabsContent value="admins" className="p-4">
          <ListUsers initialUsers={admins} profile_uuid={profile_uuid} />
        </TabsContent>
        <TabsContent value="pending" className="p-4">
          <ListUsers initialUsers={pending} />
        </TabsContent>
      </Tabs>
      <MessageAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        message={messageSuccess}
        messageContinue="Aceptar"
      />
    </>
  );
};

export default OwnerProfile;
