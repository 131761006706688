import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";
import edit from "../../assets/editicon2.png";
import { deshashear_password, getProfile } from "../../lib/supabaseProfile";
import { useAuth } from "../../contexts/AuthContext";
import { ImageRende } from "../../components/ImageRende";
import { UploadProfilePictureCard } from "../../components/ui/upload";
import UpdateProfile from "./UpdateProfile";
import NotFound from "../../components/NotFound";
import UploadMultimedia from "./UploadMultimedia";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import ListComments from "../../components/Profile/ListComments";
import OwnerProfile from "../../components/Dashboard/OwnerProfile";
import {
  update_profile_avatar,
  update_profile_banner,
} from "../../lib/supabaseProfile";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";

import { Button } from "../../components/ui/button";

const formSchema = z
  .object({
    first_name: z.string().min(1, { message: "El nombre es requerido" }),
    second_name: z.string().optional(),
    last_name: z.string().min(1, { message: "El apellido es requerido" }),
    relationship_id: z
      .number()
      .int()
      .min(1, { message: "La relación es requerida" }),
    country_id: z.number().int().min(1, { message: "El país es requerido" }),
    state_id: z.number().int().min(1, { message: "El estado es requerido" }),
    date_birth: z.string().refine((dateStr) => !isNaN(Date.parse(dateStr)), {
      message: "Ingrese una fecha válida",
    }),
    date_departure: z
      .string()
      .refine((dateStr) => !isNaN(Date.parse(dateStr)), {
        message: "Ingrese una fecha válida",
      }),
    phrase: z.string().optional(),
    biography: z.string().optional(),
    is_private: z.boolean(),
    password: z.string().optional(),
    re_pass: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.is_private && (!data.password || data.password.length !== 4)) {
        return false;
      }
      return true;
    },
    {
      path: ["password"], // Define el campo que muestra el mensaje de error
      message: "La contraseña debe ser de 4 digitos", // Mensaje de error
    }
  )
  .refine(
    (data) => {
      // Verifica que re_pass tenga 4 caracteres y coincida con password
      if (data.re_pass&&(data.re_pass.length !== 4 && data.re_pass !== data.password)) {
        return false;
      }
      return true;
    },
    {
      path: ["re_pass"], // Apunta al campo re_pass
      message:
        "La confirmación de la contraseña debe coincidir y tener 4 dígitos.",
    }
  );

const searchUserByUuid = async (user_uuid, users) => {
  if (!user_uuid) return false;
  if (!users) return false;
  // console.log("users", users);
  // console.log("user_uuid", user_uuid);
  const user = users.find((user) => user.user_uuid === user_uuid);
  if (user) {
    return true;
  }
  return false;
};
function EditProfile() {
  const { uuid } = useParams();
  const { profile, getSession } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openAvatar, setOpenAvatar] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const [user_uuid, setUserUuid] = useState("");
  const [avatar_url, setAvatarUrl] = useState("");
  const [banner_url, setBannerUrl] = useState("");
  const [owner_uuid, setOwnerUuid] = useState(null);
  const [access, setAccess] = useState(false);
  const [owner, setOwner] = useState(false);
  const navigate = useNavigate();
  const [verify, setVerify] = useState("No verificado");
  const [users, setUsers] = useState([]);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: "",
      second_name: "",
      last_name: "",
      relationship_id: 0,
      country_id: 0,
      state_id: 0,
      date_birth: "",
      date_departure: "",
      phrase: "",
      biography: "",
      is_private: false,
      password: "",
      re_pass: "",
    },
  });

  const { watch, reset } = form;

  useEffect(() => {
    const fetchProfile = async () => {
      const session = await getSession();

      if (!session) {
        navigate("/auth");
        return;
      }

      if (profile) {
        setUserUuid(profile.user_uuid);
        if (profile.user_uuid === owner_uuid) {
          setOwner(true);
        }
      }
    };

    fetchProfile();
  }, [profile, owner_uuid, getSession, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!uuid) return;
      if (!profile) return;
      setLoading(true);
      const response = await getProfile(uuid, profile.user_uuid);
      //console.log("response", response);

      if (response.success) {
        setOwnerUuid(response.data.owner);
        const users = response.data.users;
        // console.log("users", users);
        // console.log("profile", profile.user_uuid);
        setUsers(users);
        const edit = await searchUserByUuid(profile.user_uuid, users);
        // console.log("edit", edit);
        setAccess(edit);
        // if (!edit) {
        //   navigate('/dashboard');
        //   return;
        // }
        const profile_data = response.data.profile;
        console.log("profile_data", profile_data);
        const data = {
          ...profile_data,
          state_id: profile_data.state.state_id,
          country_id: profile_data.state.country.country_id,
          relationship_id: profile_data.relationship.relationship_id,
        };
        setAvatarUrl(profile_data.avatar_url);
        setBannerUrl(profile_data.banner_url);
        if (profile_data.status) {
          setVerify("Verificado");
        }
        if(data.password === null){
          data.password="";
        }
        console.log("data", data.password);
        data.password = deshashear_password(data);
        reset(data);
      } else {
        console.error(response.data);
      }
      setLoading(false);
    };
    fetchProfile();
  }, [uuid, reset, profile]);

  if (!owner) {
    if (!access && !loading) {
      return <NotFound />;
    }
  }

  const handleUpdateAvatar = () => {
    setOpenAvatar(true);
  };

  const handleUpdateBanner = () => {
    setOpenBanner(true);
  };

  const handleLookProfile = () => {
    navigate(`/perfil/${uuid}`, { replace: true });
  };

  const handleBack = () => {
    navigate("/dashboard");
  };
  return (
    <div className="min-h-screen flex flex-col">
      <div className="w-full max-w-6xl bg-white md:shadow-lg mx-auto mt-14 md:mt-24 md:rounded-lg">
        <div className="mb-10 w-full flex-col px-0 relative text-center md:text-left">
          <div className="relative w-full h-full md:rounded-lg overflow-hidden">
            <ImageRende
              src={banner_url}
              alt="Banner"
              className="w-full h-full max-h-full object-cover"
              isBanner={true}
            />
            <UploadProfilePictureCard
              user_uuid={user_uuid}
              profile_uuid={uuid}
              nameFile="banner"
              loading={loading}
              setLoading={setLoading}
              updateLocal={setBannerUrl}
              open={openBanner}
              setOpen={setOpenBanner}
              setLocalProfile={reset}
              type="banner"
              updateFunction={update_profile_banner}
            />
            <button
              className="absolute flex items-center justify-center z-30 text-black bottom-0 right-4 md:bottom-4 md:right-4 h-12 w-12 md:w-48 py-2 px-3 hover:cursor-pointer hover:bg-gray-200 rounded-md bg-white mb-2"
              onClick={handleUpdateBanner}
            >
              <i className="fas fa-upload text-l"></i>
              <span className="ml-2 text-sm hidden md:inline font-semibold">
                Actualizar Banner
              </span>
            </button>
          </div>
          {/*flex flex-col items-center translate-x-3/4 -translate-y-3/4*/}
          <div className="relative flex flex-col md:flex-row items-center justify-center md:justify-start md:pl-8">
            <div className="flex flex-col items-center md:block w-full max-w-36 relative -mt-16 md:-mt-20">
              <ImageRende
                src={avatar_url}
                alt="Avatar"
                className="rounded-full border-6 border-white w-36 h-36"
              />
              <UploadProfilePictureCard
                user_uuid={user_uuid}
                profile_uuid={uuid}
                nameFile="profile"
                loading={loading}
                setLoading={setLoading}
                updateLocal={setAvatarUrl}
                open={openAvatar}
                setOpen={setOpenAvatar}
                updateFunction={update_profile_avatar}
              />

              <img
                src={edit}
                className="md:absolute bottom-16 right-0 transform mb-25 translate-x-full -translate-y-full md:translate-x-1/4 md:translate-y-1/4 h-8 w-8 bg-red_1 rounded-full p-1 hover:bg-red-700 cursor-pointer"
                alt="edit"
                onClick={handleUpdateAvatar}
              />
            </div>
            <div className="p-0 md:pl-8 md:pt-6">
              <h2 className="text-3xl font-bold text-black">
                {watch("first_name")}
              </h2>
              <p className="text-base my-3 italic transform">
                Estado:
                <span className="text-gray-400"> {/* Estado */} </span>
                <span className="text-rojo"> {verify} </span>
              </p>
              <button
                className="bg-red_1 mr-4 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
                onClick={handleBack}
              >
                Volver
              </button>
              <Button
                className="bg-red_1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-2"
                onClick={handleLookProfile}
              >
                Ver Perfil Público
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center px-0 mt-0 md:mt-6">
        <div className="w-full max-w-6xl bg-white md:shadow-lg relative text-center md:text-left md:rounded-lg">
          <div className="my-5">
            <Tabs defaultValue="information" className="w-full">
              <TabsList className="flex items-center justify-center border-b border-gray-300 bg-white rounded-none">
                <TabsTrigger
                  value="information"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Información
                </TabsTrigger>
                <TabsTrigger
                  value="content"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Contenido
                </TabsTrigger>
                <TabsTrigger
                  value="comments"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Comentarios
                </TabsTrigger>
                {owner && (
                  <TabsTrigger
                    value="admins"
                    className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Administradores
                  </TabsTrigger>
                )}
                <ScrollArea className="w-full whitespace-nowrap md:hidden">
                  <TabsTrigger
                    value="information"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Información
                  </TabsTrigger>
                  <TabsTrigger
                    value="content"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Contenido
                  </TabsTrigger>
                  <TabsTrigger
                    value="comments"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Comentarios
                  </TabsTrigger>
                  {owner && (
                    <TabsTrigger
                      value="admins"
                      className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                    >
                      Administradores
                    </TabsTrigger>
                  )}
                  <div className="hidden">
                    <ScrollBar orientation="horizontal" />
                  </div>
                </ScrollArea>
              </TabsList>
              <TabsContent value="information" className="p-4">
                <UpdateProfile
                  user_uuid={user_uuid}
                  profile_uuid={uuid}
                  form={form}
                />
              </TabsContent>
              <TabsContent value="content" className="p-4">
                <UploadMultimedia
                  user_uuid={user_uuid}
                  profile_uuid={uuid}
                  isEdit={true}
                />
              </TabsContent>
              <TabsContent value="comments" className="p-4">
                <ListComments
                  user_uuid={user_uuid}
                  profile_uuid={uuid}
                  is_edit={true}
                />
              </TabsContent>
              {owner && (
                <TabsContent value="admins" className="px-2 py-4 sm:p-4">
                  <OwnerProfile
                    user_uuid={user_uuid}
                    profile_uuid={uuid}
                    owner={profile}
                    users={users}
                  />
                </TabsContent>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
