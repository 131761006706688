import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../lib/utils";
import { Button } from "../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

const normalizeText = (text) =>
  text ? text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() : "";

export function Combobox({
  label = "Select an option...",
  data = [{ value: "", label: "" }],
  keyField = "label",
  valueField = "value",
  value,
  onChange,
}) {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSelect = (currentValue) => {
    onChange(currentValue);
    setOpen(false);
  };

  const selectedItem = data.find((item) => item[valueField] === value);

  const normalizedSearchTerm = normalizeText(searchTerm);

  const filteredData = data.filter((item) => {
    const normalizedLabel = normalizeText(item[keyField]);
    return normalizedLabel.includes(normalizedSearchTerm);
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between border border-gray-300 py-6 text-base hover:text-gray-500 ${
            selectedItem ? "text-black" : "text-gray-500"
          }`}
        >
          {selectedItem ? selectedItem[keyField] : label}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command shouldFilter={false}>
          <CommandInput
            placeholder={`Buscar ${label}...`}
            value={searchTerm}
            onValueChange={setSearchTerm}
          />
          <CommandList>
            <CommandEmpty>{label} no encontrado.</CommandEmpty>
            <CommandGroup>
              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <CommandItem
                    key={item[valueField]}
                    value={item[valueField]}
                    onSelect={() => handleSelect(item[valueField])}
                    className={value === item[valueField] ? "text-black" : ""}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === item[valueField] ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {item[keyField]}
                  </CommandItem>
                ))
              ) : (
                <CommandEmpty>{label} no encontrado.</CommandEmpty>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
