import React, { useEffect, useState } from "react";

import ListComments from "../components/Profile/ListComments";
import { Loading } from "../components/ui/loading";
import { MessageAlert } from "../components/ui/message_alert";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../components/ui/tabs";

import { formatJoinDate } from "../lib/dateUtils";

import { Button } from "../components/ui/button";
import { useAuth } from "../contexts/AuthContext";

import Profiles from "../components/Dashboard/Profiles";
import EditUserData from "../components/Dashboard/EditUserData";

import ImageRende from "../components/ImageRende";

import { UploadProfilePictureCard } from "../components/ui/upload";

import { update_user_images } from "../lib/supabaseProfile";

import { add_medallion } from "../lib/supabaseUser";

import { useNavigate } from "react-router-dom";
import { ScrollArea, ScrollBar } from "../components/ui/scroll-area";

function Dashboard() {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const [localProfile, setLocalProfile] = useState({
    user_uuid: null,
    name: "",
    email: "",
    avatar_url: "",
    date_created: "",
    state: null,
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  // const [tabDefault, setTabDefault] = useState("profiles")

  useEffect(() => {
    const linkedMedallion = async (medallion) => {
      const response = await add_medallion(medallion, profile.user_uuid);

      if (response.status_code === 401) {
        setMessage("El medallón ya ha sido asignado a otro usuario");
        setOpenMessage(true);
      } else if (response.status_code === 200) {
        setMessage("Medallón asignado correctamente");
        setOpenMessage(true);
      } else {
        setMessage(
          "Error al asignar el medallón, consultar con el administrador"
        );
        setOpenMessage(true);
      }
    };


    if (profile) {
      setLocalProfile({
        user_uuid: profile.user_uuid,
        name: profile.name,
        email: profile.email,
        avatar_url: profile.avatar_url,
        date_created: profile.date_created,
        state: profile.state,
      });

      if (!profile.state) {
        console.log("No tiene estado");
        //setTabDefault("password");
      }

      setLoading(false);

      const action = localStorage.getItem("action");
      const redirect = localStorage.getItem("redirect");
      localStorage.removeItem("action");
      localStorage.removeItem("redirect");
      if (action) {
        if (action === "add_comment") {
          navigate(redirect + "?action=comment");
        } else if (action === "add_medallion") {
          const medallion = localStorage.getItem("medallion");
          localStorage.removeItem("medallion");
          linkedMedallion(medallion);
        }
      }
    }
  }, [profile, navigate]);

  const handleUpdateProfile = (new_url) => {
    setLocalProfile({
      ...localProfile,
      avatar_url: new_url,
    });
  };

  const handleUpdateAvatar = () => {
    setOpen(true);
  };

  return (
    <>
      {!loading && (
      <div className="w-full flex flex-col items-center px-0 mt-14 md:mt-28 mb-20">
        <div className="w-full max-w-5xl bg-white md:shadow-lg relative  text-center md:text-left md:rounded-lg px-10">
          <div className="flex flex-col md:flex-row items-center md:space-x-6 my-6">
            <div className="w-full md:w-auto relative">
              <ImageRende
                alt="Profile"
                className="h-44 md:bottom-2 rounded-full mx-auto relative z-10 mt-6 mb-3"
                src={localProfile.avatar_url}
                loading={loading}
              />
              <Button
                variant="outline"
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 justify-center md:bottom-2 md:right-4 bg-black text-white rounded-full h-8 w-8 flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-125 z-20 hover:bg-black hover:text-white"
                onClick={handleUpdateAvatar}
              >
                <i className="fas fa-camera"></i>
              </Button>
              <UploadProfilePictureCard
                user_uuid={localProfile.user_uuid}
                nameFile="profile"
                loading={loading}
                setLoading={setLoading}
                updateLocal={handleUpdateProfile}
                open={open}
                setOpen={setOpen}
                updateFunction={update_user_images}
              />
            </div>
            <div className="flex flex-col justify-center text-center md:text-left">
              <h1 className="text-xl md:text-2xl font-bold m-1">
                {localProfile.name}
              </h1>
              <h2 className="text-md m-1">{localProfile.email}</h2>
              <h2 className="text-md m-1">
                {formatJoinDate(localProfile.date_created)}
              </h2>
            </div>
          </div>

          <div className="my-5 w-full">
            <Tabs defaultValue="profiles" className="w-full">
              <TabsList className="flex items-center justify-center border-b border-gray-300 bg-white rounded-none">
                <TabsTrigger
                  value="profiles"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Perfiles
                </TabsTrigger>
                <TabsTrigger
                  value="password"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Editar datos de la cuenta
                </TabsTrigger>
                <TabsTrigger
                  value="publications"
                  className="hidden md:flex px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                >
                  Publicaciones
                </TabsTrigger>
                <ScrollArea className="w-full whitespace-nowrap md:hidden">
                  <TabsTrigger
                    value="profiles"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red_1 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Perfiles
                  </TabsTrigger>
                  <TabsTrigger
                    value="password"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Editar datos de la cuenta
                  </TabsTrigger>
                  <TabsTrigger
                    value="publications"
                    className="px-5 py-2 text-sm font-medium text-gray-700 border-b-2 border-transparent hover:border-red-500 hover:text-red_1 data-[state=active]:border-red_1 data-[state=active]:text-red_1 focus:outline-none rounded-none"
                  >
                    Publicaciones
                  </TabsTrigger>
                  <div className="hidden">
                    <ScrollBar orientation="horizontal" />
                  </div>
                </ScrollArea>
              </TabsList>

              <TabsContent value="profiles">
                <Profiles profile={localProfile} />
              </TabsContent>
              <TabsContent value="password" className="p-4">
                <EditUserData />
              </TabsContent>
              <TabsContent value="publications" className="p-4">
                <ListComments
                  profile={profile}
                  user_uuid={profile.user_uuid}
                  is_edit={false}
                  ispublications = {true}
                  open={open}
                  setOpen={setOpen}
                />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
      )}
      <Loading loading={loading} />
      <MessageAlert
        open={openMessage}
        setOpen={setOpenMessage}
        message={message}
        messageContinue={"Aceptar"}
      />
    </>
  );
}

export default Dashboard;
