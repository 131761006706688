import React, { createContext, useContext, useMemo, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkButton } from '../components/ui/LinkButton';
import { ActionButton } from '../components/ui/ActionButton';
import { useAuth } from '../contexts/AuthContext';
import { match } from 'path-to-regexp';

const HeaderContext = createContext();

const matchPath = (pathname, pattern) => {
  const matcher = match(pattern, { decode: decodeURIComponent });
  return matcher(pathname) !== false;
};

export const HeaderProvider = ({ children }) => {
  const location = useLocation();
  const { logout, profile } = useAuth();
  const navigate = useNavigate();
  const previousPath = useRef('');

  useEffect(() => {
    if (!matchPath(location.pathname, "/perfil/:value")) {
      previousPath.current = location.pathname;
    }
  }, [location.pathname]);

  // Use useCallback to memoize the function
  // const backDashboard = useCallback(() => {
  //   navigate(`/dashboard`, { replace: true });
  // }, [navigate]);

  const goBack = useCallback(() => {
    console.log("-",previousPath.current);
    navigate(previousPath.current, { replace: true });
  }, [navigate]);

  
  const headerConfig = useMemo(() => {
    const configs = {
      "/dashboard": {
        linkComponents: getDashboardLinks(),
        linkComponentsMobile: getDashboardLinksMobile(profile),
        actionComponents: getDashboardActions(logout),
        actionComponentsMobile: getDashboardActionsMobile(logout),
        showLinks: true,
      },
      "/perfil/:value": {
        linkComponents: getDashboardLinks(),
        linkComponentsMobile: getDashboardLinksMobile(profile),
        actionComponents: getProfileActions(logout, profile, goBack),
        actionComponentsMobile: getProfileActionsMobile(logout, profile, goBack),
        showLinks: true,
      },
    };

    const matchingConfig = Object.keys(configs).find((pattern) =>
      matchPath(location.pathname, pattern)
    );
    return configs[matchingConfig] || {
      linkComponents: [],
      linkComponentsMobile: [],
      actionComponents: [],
      actionComponentsMobile: [],
      showLinks: false,
    };
  }, [location.pathname, logout, profile, goBack]);

  return (
    <HeaderContext.Provider value={headerConfig}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => useContext(HeaderContext);

const getDashboardLinks = () => [
  () => <LinkButton
    href="https://www.latidoeterno.com/"
    label="Tienda"
    iconClass="fas fa-home text-xl mr-2"
    className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
  />,
  // () => <LinkButton 
  //   href="/auth"
  //   label="Mi cuenta"
  //   iconClass="fas fa-user text-xl mr-2"
  //   className = "bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
  // />,
];

const getDashboardLinksMobile = (profile) => {
  if (profile) {
  return [
      () => <LinkButton
        href="https://www.latidoeterno.com/"
        label="Tienda"
        isMobile={true}
        iconClass="fas fa-home text-xl"
        className="bg-transparent hover:bg-transparent text-black font-semibold hover:text-slate-800 py-2 px-4 border-none shadow-none"
      />,
    ]
  }
  return []
}
  // () => <LinkButton 
  //   href="/auth"
  //   label="Mi cuenta"
  //   iconClass="fas fa-user text-xl mr-2"
  //   className = "bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
  // />,
// ];

const getDashboardActions = (logout) => [
  () => <ActionButton
    label="Finalizar Sesión"
    onClick={logout}
    iconClass="fas fa-sign-out-alt text-xl mr-2"
    className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
  />,
];

const getDashboardActionsMobile = (logout) => [
  () => <ActionButton
    label="Salir"
    isMobile={true}
    onClick={logout}
    iconClass="fas fa-sign-out-alt text-xl"
    className="bg-transparent hover:bg-transparent text-black font-semibold hover:text-slate-800 py-2 px-4 border-none shadow-none"
  />,
];

const getProfileActionsMobile = (logout, profile, redirect) => {
  if (profile) {
    return [
      () => <ActionButton
        label="Volver"
        onClick={redirect}
        isMobile={true}
        iconClass="fas fa-arrow-left text-xl"
        className="bg-transparent hover:bg-transparent text-black font-semibold hover:text-slate-800 py-2 px-4 border-none shadow-none"
      />,
      () => <ActionButton
        label="Salir"
        isMobile={true}
        onClick={logout}
        iconClass="fas fa-sign-out-alt text-xl"
        className="bg-transparent hover:bg-transparent text-black font-semibold hover:text-slate-800 py-2 px-4 border-none shadow-none"
      />,
    ];
  }
  return [];
};

const getProfileActions = (logout, profile, redirect) => {
  if (profile) {
    return [
      () => <ActionButton
        label="Volver"
        onClick={redirect}
        iconClass=""
        className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
      />,
      () => <ActionButton
        label="Finalizar Sesión"
        onClick={logout}
        iconClass="fas fa-sign-out-alt text-xl mr-2"
        className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
      />,
    ];
  }
  return [];
} 
