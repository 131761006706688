import React from "react";
import Register from "../components/Auth/Register";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-center">
          <img src={logo} alt="Latido Eterno" className="h-16 mt-6" />
        </div>
        <div className="p-6 w-full">
          <div className="flex justify-center">
            <button
              onClick={() => navigate("/auth")}
              className={`px-6 py-3 text-lg font-bold text-gray-800 focus:outline-none`}
            >
              Ingresar
            </button>
            <button
              onClick={() => navigate("/register")}
              className={`px-6 py-3 text-lg font-bold text-red_1 border-b-4 border-black focus:outline-none`}
            >
              Regístrate
            </button>
          </div>
          <Register />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
