import React from 'react';

import image_404 from '../assets/404.png';
import sadface from '../assets/sadface.png';

export function NotFound() {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <div className="flex-grow flex items-center justify-center">
        <div className="bg-white rounded-lg shadow p-6 m-4 w-full lg:w-3/4 lg:max-w-lg">
          <div className="mb-4">
            <img src={image_404} alt="404 Not Found" className="w-full" />
          </div>
          <div className="text-center">
            <h1 className="font-bold text-xl mb-2 text-red">Página no encontrada.</h1>
            <p className="text-gray-700 text-base">
              ¡Lo lamentamos! La página a la que intentas acceder puede estar caducada, eliminada o no existe.
            </p>
          </div>
          <div className="flex justify-center mb-2">
            <img src={sadface} alt="carita triste" className="h-10 my-4" />
          </div>

          <div className="flex justify-center">
            <a href="https://www.latidoeterno.com/" className="inline-block bg-red_1 hover:bg-black text-white font-bold py-2 px-4 rounded">
              Regresar a tienda
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
