import React, { useState, useEffect } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form"
import { Input } from "../../components/ui/input"
import { Checkbox } from "../../components/ui/checkbox"
import { Label } from '../../components/ui/label';
import  GoogleLogin  from "./GoogleLogin";

import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import eye from '../../assets/ojo.png';
import eyeOff from '../../assets/ojocerrado.png'

const formSchema = z.object({
  email: z.string().email({ message: "Ingrese un correo valido" }),
  password: z.string().min(1 , { message: "La contraseña es requerida" }),
  remember: z.boolean().optional()
})

export function Login() {

  const { login, session, loginGoogle, resetPassword } = useAuth();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      navigate("/dashboard")
    }
  }, [session, navigate])

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      remember: false
    },
  })

  const handleGoogleSuccess = async () => {
    const response = await loginGoogle();
    if (response) {
      if (response.status_code !== 200) {
        setMessage(response.data)
      }
    }
  }

  const onSubmit = async(data) => {
    const response = await login(data.email, data.password, data.remember);

    // console.log(response);
    if (response) {
      if (response.status_code !== 200) {
        setMessage(response.data)
      }
    }
  }

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleResetPassword = async () => {
    const email = form.getValues("email");
    if (!email) {
      setMessage("Por favor, ingresa tu correo electrónico para recuperar la contraseña.");
      return;
    }
    const response = await resetPassword(email);
    if (response.error) {
      setMessage("Error al enviar el correo de recuperación de contraseña.");
    } else {
      setMessage("Correo de recuperación de contraseña enviado. Revisa tu bandeja de entrada.");
    }
  };

  return (
    <div>

      <GoogleLogin 
        handleGoogleSuccess={handleGoogleSuccess}
        text="Ingresar con Google"
      />
      <p className="mt-4 text-sm text-black text-center">
        <span className="text-lg text-black font-medium">¡Bienvenido de vuelta!</span> 
        
        
      <br />

      <span className="text-black font-medium">Por favor ingresa tu correo electrónico y contraseña a continuación.</span>
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="mt-0">
                <FormControl>
                  <Input 
                    placeholder="Email" {...field} 
                    className="w-full px-4 py-3 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                  />
                </FormControl>
                <FormMessage style={{ marginTop: 0 }}/>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative w-full mb-4">
                    <Input 
                      type={showPassword ? "text" : "password"} 
                      placeholder="Contraseña" {...field}
                      className="w-full px-4 py-3 h-12 mt-1.5 font-medium bg-gray-200 text-black text-base focus:outline-none focus:bg-white border-0"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-6 flex items-center focus:outline-none"
                      onClick={togglePasswordVisibility}
                    >
                      <img
                        src={`${showPassword ? eyeOff : eye}`}
                        alt={showPassword ? "Ocultar Contraseña" : "Mostrar Contraseña"}
                        className="h-6 w-6 text-gray-600"
                      />
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="remember"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex items-center justify-between mb-2">
                    <Label
                      htmlFor="remember"
                      className="flex items-center text-sm text-gray-600"
                    >
                      <Checkbox 
                        className="mr-2 leading-tight"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        id="remember"
                      />
                      Recordar Contraseña
                    </Label>
                    <p
                      className="text-sm text-black hover:text-gray-600 cursor-pointer"
                      onClick={handleResetPassword}
                    >
                      Recuperar Contraseña
                    </p>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex items-center text-sm justify-between mb-1 text-red_1">
            {message}
          </div>
          <Button 
            type="submit" 
            className="w-full px-4 py-3 h-12 mt-2 text-lg text-white bg-red_1 rounded-lg hover:bg-black focus:outline-none"
          >
            Ingresar</Button>
        </form>
      </Form>
    </div>
  )
}

export default Login;
