import React, { useState, useEffect } from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../../components/ui/card';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from '../../components/ui/button';
import { Loading } from "../../components/ui/loading";
import { formatJoinDate } from '../../lib/dateUtils';
import { Tabs, TabsContent } from '../../components/ui/tabs';
import ImageRende from '../ImageRende';
import { useNavigate } from 'react-router-dom';
import { 
  getMedallions,
  setMedallion,
  remove_permissions,
  remove_profile
} from '../../lib/supabaseProfile';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { Checkbox } from "../../components/ui/checkbox";
import { MessageAlert } from '../../components/ui/message_alert';

const FormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.length >= 0, {
    message: "Debes seleccionar al menos un medallón.",
  }),
});

export function CardProfile({
  name = "",
  relation = "",
  date = "",
  type = "edit",
  status = false,
  imageSrc = "",
  profile_uuid = "",
  permissions = false,
  user_uuid = null,
  owner = false,
  onProfileRemove = () => {},
}) {
  const [colorVerified, setColorVerified] = useState("text-green-500");
  const [verified, setVerified] = useState("VERIFICADO");
  const [loading, setLoading] = useState(false);
  const [medallions, setMedallions] = useState([]);
  const [open, setOpen] = useState(false);
  const [statu, setStatu] = useState(status);
  const [message, setMessage] = useState("");
  const [opendelete, setOpendelete] = useState(false);
  const [messagedelete, setMessagedelete] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (statu) {
      setColorVerified("text-green-500");
      setVerified("VERIFICADO");
    } else {
      setColorVerified("text-red_1");
      setVerified("NO VERIFICADO");
    }
  }, [statu]);

  const handleButtonClick = () => {
    navigate(`edit/${profile_uuid}`);
  };

  const handleAssignMedallion = async () => {
    setLoading(true);
    const response = await getMedallions(user_uuid);

    if (response) {
      setMedallions(response.data);
      const assignedMedallions = response.data
        .filter((item) => item.profile_uuid === profile_uuid)
        .map((item) => item.medallion_uuid);
      form.reset({ items: assignedMedallions });
    }

    setOpen(true);
    setLoading(false);
  };

  const handleLookProfile = () => {
    navigate(`/perfil/${profile_uuid}`, { replace: true });
  };
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      items: [],
    },
  });

  const handleCancel = () => {
    form.reset();
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const selectedMedallions = data.items;

    const deselectedMedallions = medallions
      .filter(item => item.profile_uuid === profile_uuid && !selectedMedallions.includes(item.medallion_uuid))
      .map(item => item.medallion_uuid);

    if (selectedMedallions.length > 0) {
      const response = await setMedallion(profile_uuid, selectedMedallions);
      if (!response.success) {
        setMessage("Error al asignar medallones");
        setLoading(false);
        return;
      }
    }

    if (deselectedMedallions.length > 0) {
      const response = await setMedallion(null, deselectedMedallions);
      if (!response.success) {
        setMessage("Error al desasignar medallones");
        setLoading(false);
        return;
      }
    }

    const response = await getMedallions(user_uuid);
    if (response) {
      const assignedMedallions = response.data.filter((item) => item.profile_uuid === profile_uuid);
      if (assignedMedallions.length === 0) {
        setStatu(false);
        setVerified("NO VERIFICADO");
        setColorVerified("text-red_1");
      } else {
        setStatu(true);
        setVerified("VERIFICADO");
        setColorVerified("text-green-500");
      }
    }

    setOpen(false);
    setMessage("");
    setLoading(false);
  };  const handleCloseClick = async () => {
    setOpendelete(true);
    if (owner) {
      setMessagedelete("¿Estás seguro de eliminar este perfil?");
    } else {
      setMessagedelete("¿Estás seguro de desvincular este perfil?");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    let response;
    if (owner) {
      response = await remove_profile(profile_uuid, user_uuid);
    } else {
      response = await remove_permissions(profile_uuid, user_uuid);
      console.log(response);
    }
    onProfileRemove(profile_uuid);
    setOpendelete(false);
    setLoading(false);
  };

  // console.log("--", medallions)

  return (
    <>
      <Card className="w-64 bg-white shadow-md rounded-lg overflow-hidden my-5 relative">
        <button 
          onClick={handleCloseClick}
          className="absolute top-2 right-2 flex items-center justify-center w-6 h-6 border rounded-full text-gray-500 hover:text-gray-700"
        >
          <Cross2Icon className="h-4 w-4" />
        </button>
        <CardHeader className="flex justify-center mt-4 py-3">
          <div className="flex justify-center">
            <ImageRende
              src={imageSrc}
              alt={`${name}'s profile`}
              className="w-24 h-24 rounded-full object-cover"
            />
          </div>
        </CardHeader>
        <CardContent className="text-center px-6 pt-4 pb-1">
          <CardTitle className="text-xl font-bold mb-2 min-h-20">
            {name}
          </CardTitle>
          <Tabs defaultValue={type}>
            <TabsContent value="edit">
              <CardDescription className="text-gray-700 mb-2">
                Relacion: {relation}
              </CardDescription>
              <CardDescription className="text-gray-500 mb-2">
                {formatJoinDate(date, false)}
              </CardDescription>
              <CardDescription className={`${colorVerified} font-semibold`}>
                {verified}
              </CardDescription>
            </TabsContent>
            <TabsContent value="favorite">
              <CardDescription className="text-gray-500 mb-2">
                {date}
              </CardDescription>
            </TabsContent>
          </Tabs>
        </CardContent>
        <CardFooter className="flex flex-col justify-around items-center px-6 pt-1 w-full">
          <Tabs defaultValue={type} className="w-full">
            <TabsContent
              value="edit"
              className="w-full data-[state=inactive]:hidden"
            >
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center w-full mb-2 p-0 gap-2">
                  <Button
                    className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 rounded w-full hover:bg-red_1 hover:text-white"
                    onClick={handleLookProfile}
                  >
                    Ver
                  </Button>
                  {permissions && (
                    <Button
                      className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 rounded w-full hover:bg-red_1 hover:text-white"
                      onClick={handleButtonClick}
                    >
                      Editar
                    </Button>
                  )}
                </div>
                <AlertDialog open={open}>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="outline"
                      className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-0 rounded w-full hover:bg-red_1 hover:text-white"
                      onClick={handleAssignMedallion}
                    >
                      Asignar Medallón
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent className="w-11/12 sm:w-full sm:max-w-lg" >
                    <AlertDialogHeader>
                      <AlertDialogTitle>Asignar medallón</AlertDialogTitle>
                      <AlertDialogDescription>
                        Seleccione un medallón para asignar a este perfil
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <Form {...form}>
                      <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-4"
                      >
                        <FormField
                          control={form.control}
                          name="items"
                          render={() => (
                            <FormItem>
                              <div className="grid grid-cols-1 gap-2">
                                {medallions.map((item) => {
                                  const isChecked = form.getValues("items").includes(item.medallion_uuid);
                                  const isDisabled = item.profile_uuid && item.profile_uuid !== profile_uuid;
                                  return (
                                    <FormItem
                                      key={item.medallion_uuid}
                                      className="flex flex-row items-start space-x-3 space-y-0"
                                    >
                                      <FormControl>
                                        <Checkbox
                                          checked={isChecked}
                                          onCheckedChange={(checked) => {
                                            if (isDisabled) return;
                                            return checked
                                              ? form.setValue("items", [...form.getValues("items"), item.medallion_uuid])
                                              : form.setValue(
                                                  "items",
                                                  form.getValues("items").filter((value) => value !== item.medallion_uuid)
                                                );
                                          }}
                                          disabled={isDisabled}
                                        />
                                      </FormControl>
                                      <FormLabel className={`text-sm font-normal ${isDisabled ? 'text-gray-400' : ''}`}>
                                        {item.medallion_uuid}
                                      </FormLabel>
                                    </FormItem>
                                  );
                                })}
                              </div>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <AlertDialogFooter>
                          <div className="flex items-center text-sm justify-between mb-1 text-red_1">
                            {message}
                          </div>
                          <div className='flex flex-row items-center justify-evenly sm:block'>
                            <AlertDialogCancel
                              className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-1 rounded hover:bg-red_1 hover:text-white"
                              onClick={handleCancel}
                            >
                              Cancelar
                            </AlertDialogCancel>
                            <AlertDialogAction
                              type="submit"
                              className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-1 rounded hover:bg-red_1 hover:text-white"
                            >
                              Asignar
                            </AlertDialogAction>
                          </div>
                        </AlertDialogFooter>
                      </form>
                    </Form>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </TabsContent>
            <TabsContent
              value="favorite"
              className="w-full data-[state=inactive]:hidden"
            >
              <div className="flex justify-around w-full">
                <Button className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-1 rounded w-full hover:bg-red_1 hover:text-white">
                  <i className="fas fa-eye"></i>
                </Button>
                <Button className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-1 rounded w-full hover:bg-red_1 hover:text-white">
                  <i className="fas fa-share-alt"></i>
                </Button>
                <Button className="bg-white border-2 border-red_1 text-red_1 px-4 py-2 m-1 rounded w-full hover:bg-red_1 hover:text-white">
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </TabsContent>
          </Tabs>
        </CardFooter>
      </Card>
      <Loading loading={loading} />
      <MessageAlert
        open={opendelete}
        setOpen={setOpendelete}
        message={messagedelete}
        messageCancel="Cancelar"
        messageContinue="Eliminar"
        actionContinue={handleDelete}
      />
    </>
  );
}

export default CardProfile;
