import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const scheme = process.env.REACT_APP_SCHEME || 'public';

const options = {
  db: {
    schema: scheme,
  },
}

export const supabase = createClient(
  supabaseUrl,
  supabaseKey,
  options
);
