import { supabase } from "../lib/supabaseClient";
import { saveError } from "./saveError";

export const response = {
  success: false,
  image_url: null,
  status_code: null,
  data: null,
};

export async function update_order_image(url, order, user_uuid) {
  const { data, error } = await supabase
    .from("content")
    .update({ order: order })
    .eq("url", url);

  if (error) {
    await saveError({
      process: "update_order_image",
      error: error,
      user_uuid: user_uuid,
      fixed: false,
    });
    response.status_code = 500;
    response.data = error;
    return response;
  }
  response.success = true;
  response.status_code = 200;
  response.data = data;
  return response;
}
