import { Label } from "@radix-ui/react-label";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../components/ui/form";
import eye from "../../assets/ojo.png";
import eyeOff from "../../assets/ojocerrado.png";
import { Button } from "../../components/ui/button";
import { verify_password } from "../../lib/supabaseProfile";
import { useState } from "react";
import { Input } from "../ui/input";

const formSchema = z.object({
  password: z.string(),
});

function CardPrivate(pass) {
  const [showPassword, setShowPassword] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [open, setOpen] = useState(true);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });

  async function onSubmit(data) {
    if (await verify_password(data, pass)) {
      setOpen(false);
      form.reset({});
    } else {
      setOpen(true);
      setMessageError("Clave incorrecta");
    }
  }
  const togglePasswordVisibility = (setFunc) => {
    return () => {
      setFunc((prevState) => !prevState);
    };
  };
  return (
    <div className={open?`w-full h-full bg-black/70 backdrop-blur-md flex items-center justify-center z-50 absolute top-0 bottom-0`:`hidden`}>
      <Card className="w-11/12 sm:w-full sm:max-w-xl bg-white shadow-2xl rounded-lg overflow-hidden my-5">
        <CardHeader className="flex justify-center items-center mt-4 py-3">
          <Label className="text-xl font-bold">Perfil Privada</Label>
        </CardHeader>
        <CardContent className="text-center px-6 pt-4 pb-1">
          <CardTitle className="text-xl font-medium mb-7">
            Ingrese Contraseña
          </CardTitle>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="relative w-full">
                        <Input
                          type="number"
                          placeholder="Contraseña"
                          style={{
                            MozAppearance: "textfield", // Firefox
                          }}
                          maxLength={4}
                          onWheel={(e) => e.target.blur()}
                          {...field}
                          className="w-full px-4 py-3 mt-1.5 mb-1.5 h-12 font-medium bg-gray-200 text-black text-base  focus:outline-none focus:bg-white border-0"
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-6 flex items-center focus:outline-none"
                          onClick={togglePasswordVisibility(setShowPassword)}
                        >
                          <img
                            src={`${showPassword ? eyeOff : eye}`}
                            alt={
                              showPassword
                                ? "Ocultar Contraseña"
                                : "Mostrar Contraseña"
                            }
                            className="h-6 w-6 text-gray-600"
                          />
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage style={{ marginTop: 0 }} />
                  </FormItem>
                )}
              />
              <div className="flex items-center text-sm justify-between mt-1.5 mb-0 text-red_1">
                {messageError}
              </div>
              <Button
                type="submit"
                className="w-full px-4 py-3 h-12 mt-2 text-lg text-white bg-red_1 rounded-lg hover:bg-black focus:outline-none"
              >
                Ingresar
              </Button>
            </form>
          </Form>
        </CardContent>
        <CardFooter className="flex flex-col justify-around items-center px-6 pt-1 w-full"></CardFooter>
      </Card>
    </div>
  );
}

export default CardPrivate;
