import React, { useState, useEffect } from "react";
import upload from "../../assets/iconsubir.png";
import { UploadProfilePictureCard } from "../../components/ui/upload";
import ListMediaContents from "../../components/Profile/ListMediaContents";

import {
  upload_profile_content,
  get_profile_content,
} from "../../lib/supabaseProfile";
import { ActionButton } from "../ui/ActionButton";

function UploadMultimedia({ user_uuid = null, profile_uuid = null, isEdit= false }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState([]);
  const [order_max, setOrder_max] = useState(1);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const getProfileContent = async () => {
      if (profile_uuid) {
        setLoading(true);
        const response = await get_profile_content(profile_uuid, user_uuid);
        if (response.success && response.data) {
          // console.log("------->", response);
          // if (!response.data.contents) {
          //   setContent(response.data);
          // }

          if (response.data.contents) {
            setContent(response.data.contents);
          }
          if (response.data.order_max) setOrder_max(response.data.order_max);
        }
      }
      setLoading(false);
    };
    getProfileContent();
  }, [profile_uuid, user_uuid]);

  const updateContent = (newContent) => {
    setContent((prevContent) => [...prevContent, newContent]);
  };

  return (
    <>
      <div className="show-content">
        <div
          className={`flex ${
            content.length > 0
              ? "flex-row justify-center items-center"
              : "flex-col justify-start items-start"
          }  sm:justify-start  gap-4 px-12 py-6`}
        >
          {content.length > 0 && (
            <div
              className="bg-gray-200 min-w-20 w-full sm:w-72 h-9 py-2 px-4 cursor-pointer rounded-lg overflow-hidden relative flex justify-center items-center"
              onClick={() => setOpen(true)}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row items-center">
                <img
                  src={upload}
                  className="h-7 w-7 text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  alt="Upload icon"
                />
                <p className="hidden sm:flex text-gray-500 text-sm ml-1 text-center">
                  Click para subir
                </p>
              </div>
            </div>
          )}
          {(content == null || content.length === 0) && (
            <div
              className="bg-gray-200 w-40 h-40 sm:w-72 sm:h-72 cursor-pointer rounded-lg overflow-hidden relative flex justify-center items-center"
              onClick={() => setOpen(true)}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
                <img
                  src={upload}
                  className="h-12 w-12 text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  alt="Upload icon"
                />
                <p className="text-gray-500 text-sm mt-1 text-center">
                  Click para subir
                </p>
              </div>
            </div>
          )}
          {content.length > 0 && (
            <ActionButton
              label={editMode ? "Guardar" : "Reorganizar"}
              onClick={() => setEditMode(!editMode)}
              iconClass={`fas ${editMode ? "fa-eye" : "fa-arrows-alt"} mr-2`}
              className="bg-transparent w-48 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded"
            />
          )}
        </div>
        <ListMediaContents
          user_uuid={user_uuid}
          editMode={editMode}
          isEdit={true}
          mediaContents={content}
          setItems={setContent}
          profile_uuid={profile_uuid}
        ></ListMediaContents>
      </div>
      <UploadProfilePictureCard
        user_uuid={user_uuid}
        profile_uuid={profile_uuid}
        loading={loading}
        setLoading={setLoading}
        updateLocal={updateContent}
        open={open}
        setOpen={setOpen}
        setLocalProfile={setLoading}
        updateFunction={upload_profile_content}
        type="content"
        order_max={order_max}
        setOrder_max={setOrder_max}
      />
    </>
  );
}

export default UploadMultimedia;
