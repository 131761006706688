export function formatJoinDate(
  joinDate,
  isUser = true
) {

  if (joinDate === "") return "";

  const now = new Date();
  const joined = new Date(joinDate);
  const differenceInTime = now - joined;
  const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

  if (differenceInDays < 1) {
    return isUser ? "Se unió hoy" : "Creado hoy";
  }
  if (differenceInDays < 7) {
    return isUser ? `Se unió hace ${differenceInDays} día${differenceInDays > 1 ? 's' : ''}` : `Creado hace ${differenceInDays} día${differenceInDays > 1 ? 's' : ''}`;
  }
    
  if (differenceInDays < 30) {
    const weeks = Math.floor(differenceInDays / 7);
    return isUser ? `Se unió hace ${weeks} semana${weeks > 1 ? 's' : ''}` : `Creado hace ${weeks} semana${weeks > 1 ? 's' : ''}`;
  }
  
  return isUser ? `Se unió el ${joined.toLocaleDateString()}` : `Creado el ${joined.toLocaleDateString()}`;
}
